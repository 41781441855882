import React, { useEffect } from 'react';
import { Replay } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, Link, Grid, Button, Typography, Divider, Box } from '@material-ui/core';

import { ROUTER } from '../../../constants';
import { formActions } from '../../../actions';
import { useScreenSize } from '../../../hooks/useScreenSize';

import KPTable from '../../../components/KPTComponents/KPTable';
import { CATEGORY, CATEGORY_TITLE } from '../../../constants/enums';
import exportProfile from '../../../utils/xhnv.exportProfile';


function DanhSachHoSo() {
  const dispatch = useDispatch();
  const isMobileMode = useScreenSize();

  const profile = useSelector(state => state.profileReducer.profile);
  const seasonList = useSelector(state => state.seasonReducer.seasonList);
  const myApplicationFormList = useSelector(state => state.formReducer.myApplicationFormList);

  const loadingPtns = useSelector(state => state.formReducer.loading);
  const loadingProfile = useSelector(state => state.profileReducer.loading);
  const loading = loadingPtns || loadingProfile;

  const handleReload = () => dispatch(formActions.getApplicationByProfileId(profile?.id));

  const renderStatus = (status) => {
    const chipProps = {
      label: 'Chưa nộp',
      color: 'default',
      variant: 'outlined',
    }

    if (status === 'in_review') {
      chipProps.label = 'Chưa được duyệt';
    } else if (status === 'need_update') {
      chipProps.color = 'secondary';
      chipProps.label = 'Cần phải sửa';
    } else if (status === 'approved') {
      chipProps.label = 'Đã được duyệt';
      chipProps.color = 'primary';
    }

    return <Chip size='small' {...chipProps} style={{ width: '100%' }} />
  }

  // Vừa vào trang thì lấy thông tin hồ sơ luôn
  useEffect(() => {
    if (!isNaN(profile?.id)) handleReload();
  }, [profile?.id]);
  var columns = [
    { title: <b>STT</b>, dataIndex: 'id', width: '10%', render: (_, row, id) => id + 1 },
    {
      title: <b>Chương trình</b>,
      dataIndex: 'category',
      width: '25%',
      render: (value) => CATEGORY_TITLE[value] || 'N/A'
    },
    {
      title: <b>{`Năm đăng ký ${isMobileMode ? '' : 'tham dự'}`}</b>,
      dataIndex: 'seasonId',
      width: '25%',
      render: (value) => seasonList.find((e) => e.id === value)?.year,
    },
    {
      title: <b>Trạng thái</b>,
      dataIndex: 'status',
      width: '20%',
      filters: [
        { text: 'Đang hoàn thiện', value: 'unsubmit' },
        { text: 'Đã nộp', value: 'in_review' },
        { text: 'Đã được duyệt', value: 'approved' },
      ],
      onFilter: (value, row) => row.status.includes(value),
      render: (value) => renderStatus(value),
    },
    {
      title: <b>Tác vụ</b>,
      dataIndex: 'actions',
      width: '20%',
      render: (_, row) => 
        <>
          {row.category === CATEGORY.QCV ? (
            <Box style={{ display: "flex", flexDirection: "row", gap: 16 }}>
              <Link href={`${ROUTER.QCV.HO_SO}/${row.id}`}>Chi tiết</Link>
            </Box>
          ) : (
            <Link style={{ cursor: "pointer" }} onClick={() => exportProfile(row)} href={undefined}>Xuất hồ sơ</Link>
          )}
        </>
        // <Box
        //   style={{
        //     display: "flex",
        //     flexDirection: "row",
        //     gap: 16
        //   }}
        // >
        //   <Link href={`${row.category === 'nstb' ? ROUTER.PTNS.HO_SO : `${ROUTER.QCV.HO_SO}/`}${row.id}`}>Chi tiết</Link>
        // </Box>
    }
  ];
  if (isMobileMode) columns = [
    { ...columns[1], width: '40%' },
    { ...columns[3], width: '40%' },
    { ...columns[4], width: '20%' },
  ];

  return (
    <Grid container spacing={1} justifyContent='center' style={{ marginTop: 32, marginBottom: 32 }}>
      <Grid item xs={7}>
        <Typography variant='h5' style={{ lineHeight: '36px' }}><b>Danh sách hồ sơ</b></Typography>
      </Grid>
      <Grid item xs={5}>
        <Button variant="outlined" startIcon={<Replay />} onClick={handleReload} style={{ float: 'right' }}>Tải lại</Button>
      </Grid>
      <Grid item xs={12}> <Divider /></Grid>
      <Grid item xs={12}>
        <KPTable
          loading={loading}
          columns={columns}
          rows={myApplicationFormList}
        />
      </Grid>
    </Grid>
  );
}

export default DanhSachHoSo;
