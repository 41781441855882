import React, { useMemo } from 'react';
import {
	Box,
	Grid,
	Select,
	Button,
	MenuItem,
	TextField,
	IconButton,
	InputLabel,
	FormControl,
	InputAdornment,
} from '@material-ui/core';
import { GetApp, Search } from '@material-ui/icons';

import { exportToXlsx } from '../../../../utils';
import { FORM_CONSTANTS } from '../../../../constants';
import { APP_SCENE, ROLE } from '../../../../constants/enums';

export default function DanhSachHoSoFind(props) {
	const { role, field, setField } = props;

  const fieldOptions = useMemo(() => ([
    { key: 'all', title: 'Toàn bộ', value: 'all' },
    ...FORM_CONSTANTS[`FIELD_${APP_SCENE}_OPTIONS`]
  ]), [APP_SCENE]);

  const fieldName = useMemo(() => fieldOptions.find((e) => e.value === field)?.title, [fieldOptions, field]);

	const handleChangeName = (e) => props.setName(e.target.value);
	const handleChangeField = (e) => {
		if (role === ROLE.SUPER_ADMIN) sessionStorage.setItem('admin_field', e.target.value);
		setField(e.target.value);
	}

	return (
		<>
			<Grid item xs={12} md={2}>
				<Button
					disabled={field === 'all'}
					fullWidth
					variant='contained'
					color='secondary'
					startIcon={<GetApp />}
					onClick={() => exportToXlsx(
						props.rows.map((e, id) => ({ ...e, realID: id + 1 }))
						.sort((a, b) => (
							(b.scoreA + b.scoreB + b.scoreC + b.scoreD) - (a.scoreA + a.scoreB + a.scoreC + a.scoreD)
						)),
						field,
						fieldName,
					)}
					style={{ color: 'white', height: 40 }}
				>
					Xuất file
				</Button>
			</Grid>
			<Grid item xs={12} md={5}>
				<FormControl size='small' variant='outlined' fullWidth>
					<InputLabel id="test-select-label">Lĩnh vực</InputLabel>
					<Select
						fullWidth
						disabled={role?.includes('qcv_admin_')}
						value={field || ''}
						MenuProps={{
							getContentAnchorEl: null,
							MenuListProps: { style: { padding: 0 } },
							PaperProps: { style: { left: 8 } },
							anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
							transformOrigin: { vertical: 'top', horizontal: 'center' },
						}}
						onChange={handleChangeField}
						label='Lĩnh vực'
					>
						{fieldOptions.map((opt) => <MenuItem key={opt.key} value={opt.key}>{opt.title}</MenuItem>)}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={5}>
				<Box width='100%' display='flex' justifyContent='center'>
					<TextField
						fullWidth
						size='small'
						label='Tìm kiếm ứng viên'
						variant='outlined'
						onChange={handleChangeName}
						InputProps={{ endAdornment: 
							<InputAdornment position='end'>
								<IconButton type='submit'>
									<Search />
								</IconButton>
							</InputAdornment>
						}}
					/>
				</Box>
			</Grid>
		</>
	)
}
