import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import {
  Box,
  Grid,
  Avatar,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  FormControl,
  Typography,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { apiCall } from "../../../utils";
import { useScene } from "../../../hooks/useScene";
import { useStyles } from "./styled";
import { userActions, voteActions } from "../../../actions";
import { APP_SCENE, FORM_CONSTANTS, PROFILE_API, ROUTER } from "../../../constants";

import qcvVote from "../../../assets/image/tnv/qcv-vote.jpg";
import logoDoan from "../../../assets/image/home/logoDoan.png";
import logoBoKHCN from "../../../assets/image/home/logoBoKHCN.png";
import Loading from "../../CustomComponents/Loading";

const fielQCVdOptions = [
  { key: 'all', title: 'Toàn bộ', value: 'all' },
  ...FORM_CONSTANTS.FIELD_QCV_OPTIONS,
];

const fielQCVdOptionsObj = {};

fielQCVdOptions.forEach(f => {
  fielQCVdOptionsObj[f.key] = f.title;
});

const BinhChonV2 = () => {
  const scene = useScene();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refreshToken = localStorage.getItem('refresh_token');

  const [filter, setFilter] = useState({ option: 'all' });
  const [allProfile, setAllProfile] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const vote = useSelector(state => state.voteReducer);
	const loadingVote = useSelector(state => state.voteReducer.loading);
  const role = useSelector(state => state.userReducer.user?.role);
  const profile = useSelector(state => state.userReducer.user);
  const openSeasonId = useSelector((state) => state.seasonReducer.openSeasonId);

  const fieldOptions = useMemo(() => {
    if (scene.isQuaCauVang) {
      return ([
        { key: 'all', title: 'Toàn bộ', value: 'all' },
        ...FORM_CONSTANTS.FIELD_QCV_OPTIONS,
      ]) 
    }
  }, [scene]);


  const profiles = useMemo(() => {
    if (filter.option === "all") {
      // allProfile.sort((a, b) => a.scoreG - b.scoreG);
      return allProfile.sort((a, b) => a.id - b.id);
    } else { 
      const list = allProfile.filter((p) => p.field === filter.option);
      // list.sort((a, b) => a.scoreG - b.scoreG);
      return list.sort((a, b) => a.scoreG - b.scoreG);
    }
  }, [filter.option, allProfile]);

  const checkVoted = (p) => {
    const isVoted = vote.votedList.find(v => v.applicationFormId === p.id) ? true : false;
    return isVoted;
  }

  const handleFilter = async () => {
    const api = PROFILE_API.getProfileFilterForm();

    setLoading(true);
    const { response, error } = await apiCall({
      ...api,
      payload: {
        seasonId: openSeasonId,
        category: APP_SCENE,
        voteStatus: "top20",
        status: ["approved"]
      }
    });
    setLoading(false);
    if (!error && response.status === 200 && response.data.success) {
      setAllProfile(response.data.data || []);
    }
  };

  const handleGetVote = () => {
    const voteInfo = {
      credential: profile?.username,
      type: 'council',
    }

    dispatch(voteActions.getVoteInfo({ credential: voteInfo?.credential }));
    dispatch(voteActions.setVoteInfo(voteInfo));
    dispatch(voteActions.getVoteCount());
  }

  const handleVote = (profileVote) => {
    const oldVotes = vote.votedList.map(v => v.applicationFormId);
    const voteInfo = {
      ...vote.voteInfo,
      listFormId: [...oldVotes, profileVote.id],
    }

    if (voteInfo.listFormId.length > 20) {
      dispatch(userActions.notification({ message: 'Chỉ được bình chọn tối đa 20 ứng viên!', type: 'error' }));
    } else {
      dispatch(voteActions.vote(voteInfo, () => {
        handleGetVote();
        dispatch(userActions.notification({ message: 'Bình chọn thành công!', type: 'success' }));
      }));
    }
  }

  const handleUnVote = (profileVote) => {
    const oldVotes = vote.votedList.map(v => v.applicationFormId);
    const newVotes = oldVotes.filter(v => v !== profileVote.id);
    const voteInfo = {
      ...vote.voteInfo,
      listFormId: [...newVotes],
    }

    dispatch(voteActions.vote(voteInfo, () => {
      handleGetVote();
      dispatch(userActions.notification({ message: 'Bỏ bình chọn thành công', type: 'success' }));
    }));
  }

  useEffect(() => {
    handleGetVote();
    handleFilter();
  }, [profile]);

  useEffect(() => {
    if (!refreshToken || (role && role !== "super_admin" && role !== "council" && role !== "council_qcv" && role !== "council_ptns")) {
      navigate(ROUTER.HOME);
    }
  }, [role]);


  return (
    <Box className={classes.root}>
      <Box className={classes.imageHead}>
        <Box className={classes.logoHeader} >
          <Box mr={"40px"} className="group-logo">
            <img width={80} src={logoDoan} style={{ margin: 'auto' }} />
            <Typography>TRUNG ƯƠNG ĐOÀN TNCS HỒ CHÍ MINH</Typography>
          </Box>
          <Box className="group-logo">
            <img width={80} src={logoBoKHCN} />
            <Typography>BỘ KHOA HỌC VÀ CÔNG NGHỆ</Typography>
          </Box>
        </Box>
      </Box>

      <Grid container spacing={3} justifyContent='space-between' className={classes.filter}>
        <Grid item xs={12} md={6} lg={4}>
          <Button
            color='secondary'
            variant='contained'
            disabled={role !== "super_admin"}
            onClick={() => navigate(ROUTER.QCV.KET_QUA_BINH_CHON)}
          >
            Xem kết quả bình chọn (admin)
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl size='small' variant='outlined' fullWidth>
            <InputLabel id="test-select-label">Lĩnh vực</InputLabel>
            <Select
              fullWidth
              value={filter.option}
              MenuProps={{
                getContentAnchorEl: null,
                MenuListProps: { style: { padding: 0 } },
                PaperProps: { style: { left: 8 } },
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
              }}
              onChange={(e) => setFilter({ ...filter, option: e.target.value })}
              label='Lĩnh vực'
            >
              {fieldOptions?.map((opt) => <MenuItem key={opt.key} value={opt.key}>{opt.title}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {profiles.map((p, index) => {
          return (
            <Grid
              item
              md={6} lg={4} xs={12} sm={12}
              key={index}
              classes={{ root: classes.gridCard }}
              style={{ height: '100%' }}
            >
              <Card style={{ borderRadius: 8 }}>
                <CardActionArea className="card-area">
                  <CardMedia
                    component="img"
                    classes={{ root: classes.header }}
                    src={qcvVote}
                  />
                  <Avatar
                    src={`${process.env.REACT_APP_DOMAIN}${p?.profile?.photoUrl}`}
                    style={{
                      width: 100,
                      height: 100,
                      border: "solid 4px #fff",
                      margin: '-50px auto 0 auto',
                    }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      height: "125px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "1.3rem",
                        marginBottom: "20px",
                        fontWeight: "bold",
                        fontFamily: "WorkSans-ExtraBold"
                      }}
                    >
                      {p.profile.name}
                    </Typography>
                    <Box className={classes.info}>
                      <Typography style={{ width: '100%', textAlign: "center" }}><b>Lĩnh vực: </b>{fielQCVdOptionsObj[p.field]}</Typography>
                      <Typography style={{ width: '100%', textAlign: "center" }}><b>Ngày sinh: </b>{dayjs(p?.profile?.birth).format("DD/MM/YYYY")}</Typography>
                    </Box>
                  </CardContent>
                  <Box className={classes.vote}>
                    <Button
                      style={{
                        backgroundColor: checkVoted(p) ? "#EE6064" : undefined,
                        color: checkVoted(p) ? "#FFFFFF" : undefined,
                        border: checkVoted(p) ? "0px" : undefined,
                        margin: 'auto'
                      }}
                      startIcon={checkVoted(p) ? <Favorite color="#FFFFFF" /> : <FavoriteBorder />}
                      onClick={() => {
                        checkVoted(p) ? handleUnVote(p) : handleVote(p)
                      }}
                    >{checkVoted(p) ? "Đã bình chọn" : "Bình chọn"}</Button>
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          )
        })}
      </Grid>
      <Loading open={loading || loadingVote} />
    </Box>
  )
}

export default BinhChonV2;