/* eslint-disable max-len */
import dayjs from "dayjs";
import React, { useMemo, useEffect, useState } from "react";
import {
  Box,
  Card,
  Grid,
  Button,
  Dialog,
  Divider,
  Checkbox,
  Typography,
  DialogContent,
  FormControlLabel,
  DialogActions,
  DialogTitle,
  IconButton,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Edit, Close, WarningRounded, Home } from "@material-ui/icons";

import KPTable from "../../../../components/KPTComponents/KPTable";
import InputActive from "../../InputActive";
import PreviewModal from "../../../../components/CustomComponents/PreviewModal";

import theme from "../../../../theme";
import KPTextform from "../../../../components/KPTComponents/KPTextform";
import exportProfile from '../../../../utils/qcv.exportProfile';
import fileCongVanMau from "../../../../assets/docs/QCV2024_Mẫu công văn giới thiệu.doc";

import { photoRender } from "../../../../utils/photoRender";
import { columnRender } from "../../../../utils/DangKyHoSoColumnRender";
import { useScreenSize } from "../../../../hooks/useScreenSize";
import { jumpToReleventDiv } from "../../../../components/KPTComponents/utils";
import { ROUTER,FORM_CONSTANTS } from "../../../../constants";
import { capitalizeFirstLetter, getWordCount } from "../../../../utils";
import { fileActions, formActions, userActions } from "../../../../actions";
import { ARCHIVEMENT_TITLE, ARCHIVEMENT_TYPE, CATEGORY, KEYS, SECONDARY_COLOR } from "../../../../constants/enums";


function DangKyHoSoUpdate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobileMode = useScreenSize();

	const [files, setFiles] = useState(null);
  const [values, setValues] = useState(null);
  const [preview, setPreview] = useState(null);
  const [showCSKH, setShowCSKH] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
  const [warningOpen, setWarningOpen] = useState(true);
  const [acceptRules, setAcceptRules] = useState(false);
  const [socialActivity, setSocialActivity] = useState([]);
  const [personalProcess, setPersonalProcess] = useState([]);
  const [otherAchievement, setOtherAchievement] = useState([]);

  const application = useSelector((state) => state.formReducer.applicationList?.[0] || null);

  const loading = useSelector((state) => {
    const loadingUser = state.userReducer.loading;
    const loadingForm = state.formReducer.loading;
    const loadingProfile = state.profileReducer.loading;
    return loadingUser || loadingForm || loadingProfile || isLoading;
  })

  const { seasons, openSeasonId, profile } = useSelector((state) => ({
    profile: state.profileReducer.profile,
    seasons: state.seasonReducer.seasonList,
    openSeasonId: state.seasonReducer.openSeasonId,
  }));

  const achievementData = useSelector((state) => ({
    [ARCHIVEMENT_TYPE.REWARD]: state.rewardReducer.personalRewardList,
    [ARCHIVEMENT_TYPE.SOLUTION]: state.solutionReducer.personalSolutionList,
    [ARCHIVEMENT_TYPE.MONOGRAPH]: state.monographReducer.personalMonographList,
    [ARCHIVEMENT_TYPE.TECH_COMPANY]: state.techCompanyReducer.personalTechCompanyList,
    [ARCHIVEMENT_TYPE.HR_DEVELOPMENT]: state.hrDevelopmentReducer.personalHrDevelopmentList,
    [ARCHIVEMENT_TYPE.RESEARCH_PROJECT]: state.researchProjectReducer.personalResearchProjectList,
    [ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT]: state.reportsReducer.personalReportList,
    [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH]: state.researchReducer.personalResearchList,
    [ARCHIVEMENT_TYPE.HR_DEVELOPMENT + KEYS.DAO_TAO]: state.hrDevelopmentReducer.personalHrDevelopmentList.filter((e) => e.type === KEYS.DAO_TAO),
    [ARCHIVEMENT_TYPE.HR_DEVELOPMENT + KEYS.BOI_DUONG]: state.hrDevelopmentReducer.personalHrDevelopmentList.filter((e) => e.type === KEYS.BOI_DUONG),
    [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC]: state.researchReducer.personalResearchList.filter((e) => e.filterType === KEYS.DOMESTIC),
    [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL]: state.researchReducer.personalResearchList.filter((e) => e.filterType === KEYS.INTERNATIONAL),
  }));

  const archivements = {
    [ARCHIVEMENT_TYPE.SOLUTION]: ARCHIVEMENT_TITLE[ARCHIVEMENT_TYPE.SOLUTION],
    [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL]: ARCHIVEMENT_TITLE[ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL],
    [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC]: ARCHIVEMENT_TITLE[ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC],
    [ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT]: ARCHIVEMENT_TITLE[ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT],
    [ARCHIVEMENT_TYPE.RESEARCH_PROJECT]: ARCHIVEMENT_TITLE[ARCHIVEMENT_TYPE.RESEARCH_PROJECT],
    [ARCHIVEMENT_TYPE.REWARD]: ARCHIVEMENT_TITLE[ARCHIVEMENT_TYPE.REWARD],
    [ARCHIVEMENT_TYPE.MONOGRAPH]: ARCHIVEMENT_TITLE[ARCHIVEMENT_TYPE.MONOGRAPH],
    [ARCHIVEMENT_TYPE.HR_DEVELOPMENT + KEYS.DAO_TAO]: ARCHIVEMENT_TITLE[ARCHIVEMENT_TYPE.HR_DEVELOPMENT + KEYS.DAO_TAO],
    [ARCHIVEMENT_TYPE.HR_DEVELOPMENT + KEYS.BOI_DUONG]: ARCHIVEMENT_TITLE[ARCHIVEMENT_TYPE.HR_DEVELOPMENT + KEYS.BOI_DUONG],
    [ARCHIVEMENT_TYPE.TECH_COMPANY]: ARCHIVEMENT_TITLE[ARCHIVEMENT_TYPE.TECH_COMPANY],
  }

  const archivementFields = Object.keys(archivements).map((key) => ({
    type: key,
    title: archivements[key],
    data: achievementData[key],
  }))

  const openSeason = useMemo(() => seasons.find((s) => s.id === openSeasonId), [seasons, openSeasonId]);
  
  const isNotAvalaible = useMemo(() => {
    if (!openSeason) return false;
    const { openDate, closeDate } = openSeason;
    const currDate = new Date();
    if (currDate < new Date(openDate)) return 'Chưa mở cổng đăng ký';
    if (currDate > new Date(closeDate)) return 'Đã hết thời hạn đăng ký';
    return null;
  }, [openSeason]);

  const handleFileChange = (e, name) => setFiles({ ...files, [name]: e });

  const handleChangeActivityForm = (name, event, type, values, setValues) => {
    const keyList = name.split(".");
    const key = keyList[2];
    const index = parseInt(keyList[1]);

    const tmp = [...values];
    if(type === "date"){
      tmp.splice(index, 1, { ...tmp[index], [key]: event});
    } else if (type === "removeFile") {
      tmp.splice(index, 1, { ...tmp[index], [key]: undefined }); 
    } else {
      tmp.splice(index, 1, { ...tmp[index], [key]: event.target.value });
    }
    setValues(tmp);
  };

  const handleReload = (profileId) => {
    dispatch(formActions.getApplicationFormv2(profileId));
    dispatch(formActions.filterApplicationForm({
      category: CATEGORY.QCV,
      seasonId: openSeasonId,
      profileId: profile.id,
    })).then((applications) => {
      if (applications.length > 0) {
        const application = applications[0];
        if (application.process === "") application.process = "[]";
        if (application.previewFile === "") application.previewFile = "[]";
        if (application.socialActivity === "") application.socialActivity = "[]";
        if (application.communicationActivity === "") application.communicationActivity = "[]";

        const fileList = JSON.parse(application?.previewFile);
        application.sumaryFile = fileList.filter((file) => file.includes(('sumaryFile')));

        if (application) {
          setValues({ ...values, ...application });
          setSocialActivity(JSON.parse(application.socialActivity) || []);
          setPersonalProcess(JSON.parse(application.process) || []);
          setOtherAchievement(JSON.parse(application.communicationActivity) || []);
        }
      } else {
        setValues({ ...values, summary: ' ' });
      }
    });
  }

  const handleSave = async (status) => {
    const formFields = [
      ...textForm.partI,
      ...textForm.partII,
      ...textForm.summary
    ];
    for (const elm of formFields) {
      if (status === "unsubmit" && elm.key === "sumaryFile") continue;

      const elmValue = elm?.value || values?.[elm?.key];
      if (!elm?.disabled && elm?.required && !elmValue) {
        setValues({ ...values, emptyKey: elm.key });
        jumpToReleventDiv(elm.key);
        dispatch(userActions.notification({
          message: `Không được để trống thông tin về: ${elm?.label}`,
          type: "error"
        }));
        return;
      }
    }

    if (!profile?.id) {
      dispatch(userActions.notification({
        message: "Lỗi không xác định, vui lòng tải lại trang",
        type: "error"
      }));
    }

    dispatch(fileActions.importMultiFileQCV(profile?.id, files)).then((response) => {
      const achivementFormData = {};
      [
        ARCHIVEMENT_TYPE.REWARD,
        ARCHIVEMENT_TYPE.SOLUTION,
        ARCHIVEMENT_TYPE.MONOGRAPH,
        ARCHIVEMENT_TYPE.TECH_COMPANY,
        ARCHIVEMENT_TYPE.HR_DEVELOPMENT,
        ARCHIVEMENT_TYPE.RESEARCH_PROJECT,
        ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT,
        ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH
      ].forEach((key) => {
        achivementFormData['form' + capitalizeFirstLetter(key)] = achievementData[key].map((e) => ({ [key + 'Id']: e.id }))
      });

      const getFile = (key, index) => response?.find((file) => {
        const name = file.split(`/storage/awardRegister/profile/${profile?.id}/`)[1];
        const keyList = name.split(".");
        return keyList[0] === key && parseInt(keyList[1]) === index;
      });

      const _process = personalProcess.filter(elm => Object.values(elm).length !== 0);
      const _socialActivity = socialActivity
        .filter(elm => Object.values(elm).length !== 0)
        .map((elm, index) => ({
          ...elm,
          communityAchievementFile: getFile("socialActivity", index) || elm?.communityAchievementFile || []
        }));
      const _communicationActivity = otherAchievement
        .filter(elm => Object.values(elm).length !== 0)
        .map((elm, index) => ({
          ...elm,
          achievementFile: getFile("otherAchievement", index) || elm?.achievementFile || []
        }));

      var previewFile = JSON.parse(application?.previewFile || '[]');
      const sumaryFiles = response?.filter((file) => file.includes('sumaryFile'));
      if (sumaryFiles.length > 0) previewFile = sumaryFiles;

      const payload = {
        ...values,
        ...achivementFormData,
        status,
        seasonId: openSeasonId,
        category: CATEGORY.QCV,
        profileId: profile.id,
        process: JSON.stringify(_process),
        socialActivity: JSON.stringify(_socialActivity),
        communicationActivity: JSON.stringify(_communicationActivity),
        previewFile: JSON.stringify(previewFile),
      };

      if (!application) {
        // Hồ sơ rỗng thì tạo hồ sơ mới
        dispatch(formActions.insertApplicationForm(payload)).then((success) => {
          if (success && status === 'in_review') window.location.reload();
        });
      } else {
        // không thì cập nhật đè lên hồ sơ cũ
        dispatch(formActions.updateApplicationForm(payload)).then((success) => {
          if (success && status === 'in_review') window.location.reload();
      });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave("in_review");
  };

  useEffect(() => {
    // Vừa vào trang thì lấy thông tin hồ sơ luôn
    if (!isNaN(profile?.id) && !isNaN(openSeasonId) && !application) {
      handleReload(profile.id);
    } else if (isNaN(profile?.id)) {
      setValues({ ...values, summary: ' ' });
    }
  }, [openSeasonId, profile?.id]);

  useEffect(() => {
    if (values?.sumaryFile?.[0]) {
      setFiles({ ...files, sumaryFile: values?.sumaryFile?.[0] });
    }
  }, [values?.sumaryFile]);

  const textForm = {
    base: [
      { key: 'name', width: isMobileMode ? 12 : 8, label: 'Họ và tên', value: profile?.name, required: true, disabled: true },
      {
        key: 'birth',
        width: isMobileMode ? 12 : 4,
        label: 'Ngày sinh',
        value: dayjs(profile?.birth).format('DD/MM/YYYY'),
        type: 'date',
        disabled: true,
        required: true,
      },
      { key: "gender", width: isMobileMode ? 6 : 3, label: "Giới tính", value: profile?.gender, options: FORM_CONSTANTS.GENDER_OPTIONS, disabled: true },
      { key: "ethnic", width: isMobileMode ? 6 : 3, label: "Dân tộc", value: profile?.ethnic, disabled: true },
      { key: 'phone', width: isMobileMode ? 12 : 6, label: 'Số điện thoại', value: profile?.phone, required: true, disabled: true },
      { key: 'email', width: 12, label: 'Email', value: profile?.email, required: true, disabled: true },
      { key: 'website', width: 12, height: 1, label: 'Website/Facebook', required: true, value: profile?.website, disabled: true },
    ],
    partI: [
      { key: "unit", width: isMobileMode ? 12 : 7, label: "Đơn vị công tác", required: true },
      {
        key: "field",
        width: isMobileMode ? 12 : 5,
        label: "Lĩnh vực",
        required: true,
        options: FORM_CONSTANTS.FIELD_QCV_OPTIONS,
      },
      { key: "nominator", width: 12, label: "Đơn vị đề cử", required: true },
      {
        key: "facultyPhone",
        width: 12,
        label: 'Link file công văn cơ quan, đơn vị đề cử',
        labelRender: (
          <Typography className="kpt-textfield-label" style={{ display: "inline" }}>
            {`Link file công văn cơ quan, đơn vị đề cử (Tải file mẫu `}
            <a
              href={fileCongVanMau}
              style={{ color: SECONDARY_COLOR }}
              download={"File công văn mẫu.docx"}
            >
              tại đây
            </a>
            {")"}
          </Typography>
        ),
        required: true,
      },
    ],
    partII: [
      {
        key: "academicLevel",
        width: 6,
        label: "Trình độ học vấn",
        required: true,
        value: values?.academicLevel,
        options: FORM_CONSTANTS.ACADEMIC_LEVEL_OPTIONS,
      },
      {
        key: "academicRank",
        width: 6,
        label: isMobileMode ? "Học hàm/chức danh" : "Học hàm/chức danh khoa học",
        value: values?.academicRank,
      },
      {
        key: "languague",
        width: 6,
        label: "Ngoại ngữ",
        required: true,
        value: values?.languague,
      },
      {
        key: "politicalUnion",
        width: 6,
        label: "Đoàn thể chính trị",
        required: true,
        value: values?.politicalUnion,
        options: FORM_CONSTANTS.POLITICAL_UNION_OPTIONS,
      },
      {
        key: "gifted",
        width: 12,
        height: 2,
        label: "Năng khiếu",
        required: true,
        value: values?.gifted,
      },
      {
        key: "videoUrl",
        width: 12,
        label: "Link video giới thiệu bản thân",
        required: true,
        value: values?.videoUrl,
      },
    ],
    summary: [
      {
        key: "summary",
        width: 12,
        height: 4,
        label: "Giới thiệu công trình nghiên cứu, giải pháp công nghệ, sáng chế",
        labelRender: (
          <>
            <Typography className="kpt-textfield-label">
              Giới thiệu công trình nghiên cứu, giải pháp công nghệ, sáng chế tiêu biểu nhất của ứng viên đã công bố
              <span style={{ color: theme.palette.secondary.main }}>*</span>:
              <br />
              <i style={{ fontSize: 16, fontWeight: 100 }}>
                1) Tên công trình nghiên cứu;<br />
                2) Mục tiêu, phương pháp và tính mới trong nghiên cứu;<br />
                3) Vai trò tham gia và đóng góp của ứng viên;<br />
                4) Giá trị khoa học của công trình;<br />
                5) Giá trị thực tiễn của công trình.<br />
              </i>
              <span style={{ fontSize: 16, fontWeight: 100 }}>
                Bản giới thiệu không quá 1000 từ và đính kèm xác nhận của cơ quan có thẩm quyền
              </span>
            </Typography>
          </>
        ),
        type: "richText",
        value: values?.summary,
        required: true,
      },
      {
        key: "countWord",
        width: 12,
        value: true,
        render: <Typography variant="subtitle1">{`Số lượng từ: ${getWordCount(values?.summary || "")} / 1000`}</Typography>
      },
      {
        key: 'sumaryFile',
        width: 12,
        label: 'Bản giới thiệu công trình có xác nhận của cơ quan có thẩm quyền',
        labelRender: (
          <>
            <Typography variant='body1' style={{ fontSize: 17 }}>
              <b>Bản giới thiệu công trình có xác nhận của cơ quan có thẩm quyền</b>
            </Typography>
            <Typography variant='body1'>
              (Bản scan, chữ ký, dấu. Trích xuất bản giới thiệu bản thân&nbsp;
              <a onClick={async (event) => {
                event.stopPropagation();
                setIsLoading(true);
                await handleSave('unsubmit');
                exportProfile(application);
                setIsLoading(false);
              }} style={{ textDecoration: 'underline', color: SECONDARY_COLOR }}>
                tại đây
              </a>
              )
            </Typography>
          </>
        ),
        type: 'file',
        required: true,
      },
    ]
  }
  
  const actives = [
    {
      key: "personalProcess",
      values: personalProcess,
      setValues: setPersonalProcess,
      label: "Quá trình học tập, công tác:",
      fields: [
        { key: "startAt", width: isMobileMode ? 12 : 2, label: "Thời gian bắt đầu", type:"date", required: true },
        { key: "finishAt", width: isMobileMode ? 12 : 2, label: "Thời gian kết thúc", type:"date", required: true },
        { key: "department", width: isMobileMode ? 12 : 3, label: "Đơn vị công tác", required: true },
        { key: "works", width: isMobileMode ? 12 : 4, label: "Công việc đảm nhiệm", required: true },
      ],
    },
    {
      key: "otherAchievement",
      values: otherAchievement,
      setValues: setOtherAchievement,
      label: " Thành tích khen thưởng (Bằng khen, giấy khen...):",
      fields: [
        { key: "achievementContent", width: isMobileMode ? 12 : 4, label: "Nội dung khen thưởng", required:true },
        { key: "typeOfAchievement", width: isMobileMode ? 12 : 3, label: "Cấp khen thưởng", options: FORM_CONSTANTS.LEVEL_INPUT_OPTIONS, required:true },
        { key: "achievementFile", label: "Minh chứng:", width: isMobileMode ? 12 : 4, type: "file", required:true },
      ],
    },
    {
      key: "socialActivity",
      values: socialActivity,
      setValues: setSocialActivity,
      label: "Hoạt động tình nguyện vì cộng đồng:",
      fields: [
        { key: "communityAchievement", width: isMobileMode ? 12 : 7, label: "Miêu tả" },
        { key: "communityAchievementFile", label: "Minh chứng", width: isMobileMode ? 12 : 4, type: "file" },
      ],
    },
  ];

  const topPart = isMobileMode ? textForm.base.slice(0, 4) : textForm.base;
  const botPart = isMobileMode ? textForm.base.slice(4, textForm.base.length) : [];
  // const partII = textForm.partII;

  return (
    <>
      <Card style={{ width: '100%', marginTop: 64 }}>
        <Box height={6} bgcolor='#233871' />
        <Box padding={2}>
          <Typography variant='h4' style={{ fontFamily: "SFProDisplay-bold", textAlign: 'center', textTransform: 'uppercase' }}>
            <b>Đăng ký hồ sơ</b>
          </Typography>
        </Box>
      </Card>

      <Card style={{ width: '100%', marginTop: 32, display: showCSKH ? 'block' : 'none' }}>
        <CardHeader style={{ paddingRight: 4, paddingTop: 2, paddingBottom: 2 }} title={
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant="h6">
              <b>Hỗ trợ đăng ký Giải thưởng KHCN Quả Cầu Vàng</b>
            </Typography>
            <IconButton onClick={() => setShowCSKH(false)}><Close /></IconButton>
          </Box>
          }
        />
        <Divider />
        <CardContent style={{ paddingTop: 2, paddingBottom: 2 }}>
          <ul>
            <li>Hỗ trợ nội dung giải thưởng: Ms Linh (<a href='http://zalo.me/0918023066'>0918.023.066</a>)</li>
            <li style={{ marginTop: 4 }}>Hỗ trợ kỹ thuật hệ thống: Mr Kiên (<a href='http://zalo.me/0373699131'>037 3699131</a>)</li>
          </ul>
        </CardContent>
      </Card>

      <form className='dkhs-container' id='form-update' defaultValue={values} onSubmit={handleSubmit}>
        <Card style={{ width: '100%' }}>
          <CardContent>
            <Box display='flex'>
              <Box flexGrow={1} marginTop='4px'>
                <KPTextform component={Box} textForm={topPart} />
              </Box>
              <Box marginTop='4px' marginLeft={1}>
                <Typography className='kpt-textfield-label'>
                  {isMobileMode ? 'Ảnh chân dung' : 'Ảnh chân dung (4x6)'}
                  <span style={{ color: theme.palette.secondary.main }}>*</span>
                  {':'}
                </Typography>
                <Card className='dkhs-photoContainer'>
                  <img
                    alt='anh-chan-dung'
                    className='dkhs-photo'
                    src={photoRender(profile?.photoUrl)}
                  />
                </Card>
              </Box>
            </Box>
            <Box flexGrow={1} marginTop='4px'>
              <KPTextform component='div' textForm={botPart} />
            </Box>
          </CardContent>
        </Card>
        <Card style={{ width: '100%', marginTop: 32 }}>
          <CardContent>
            <KPTextform
              component={Box}
              textForm={textForm.partI}
              values={values}
              setValues={setValues}
              handleSubmit={handleSubmit}
            />
          </CardContent>
        </Card>
        <Card style={{ width: '100%', marginTop: 32 }}>
          <CardContent>
            <KPTextform
              component={Box}
              textForm={textForm.partII}
              values={values}
              setValues={setValues}
            />
          </CardContent>
        </Card>
        <Card style={{ width: "100%", marginTop: 32 }}>
          <CardContent>
            {actives.map((active, index) => (
              <React.Fragment key={active.key}>
                {index > 0 && <Divider style={{ width: "calc(100% + 32px)", margin: "16px -16px" }} />}
                <InputActive
                  id={active.key}
                  {...active}
                  values={active.values}
                  setValues={active.setValues}
                  setPreview={setPreview}
                  handleFileChange={handleFileChange}
                  handleChangeForm={handleChangeActivityForm}
                />
              </React.Fragment>
            ))}
          </CardContent>
        </Card>
        <Card style={{ width: '100%', marginTop: 64 }}>
          <Box height={6} bgcolor='#233871' />
          <Box padding={2}>
            <Typography variant='h4' style={{ fontFamily: "SFProDisplay-bold", textAlign: 'center', textTransform: 'uppercase' }}>
              <b>Thành tích cá nhân</b>
            </Typography>
          </Box>
        </Card>
        {archivementFields.map((e, id) => (
          <Card key={id} style={{ width: "100%", marginTop: 32 }}>
            <Box padding={2}>
              <Typography variant="h6">
                <b>{`${id + 1}. ${e?.title}`}</b>
              </Typography>
            </Box>
            <Divider />
            <KPTable
              nonBorder={true}
              nonHover={true}
              headerColor="#FFF"
              columns={columnRender(e, null, setPreview)}
              rows={e.data}
            />
          </Card>
        ))}
        <Card style={{ width: '100%', marginTop: 64 }}>
          <CardContent>
            {values?.summary && (
              <KPTextform
                component={Box}
                textForm={textForm.summary}
                values={values}
                setValues={setValues}
              />
            )}
          </CardContent>
        </Card>
        <Card style={{ width: '100%', marginTop: 16 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={acceptRules}
                      onChange={() => setAcceptRules(!acceptRules)}
                    />
                  }
                  label={
                    <Typography variant="subtitle1">
                      <i style={{ fontFamily: "SFProDisplay" }}>
                        Tôi xin hoàn toàn chịu trách nhiệm trước pháp luật, Ban tổ
                        chức Giải thưởng về tính chính xác và các thông tin đã
                        khai trong hồ sơ đăng ký trực tuyến tham gia xét giải
                        thưởng
                      </i>
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth disabled={!acceptRules} variant="outlined" color="primary" onClick={() => handleSave("unsubmit")}>Lưu bản nháp</Button>
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth disabled={!acceptRules} type="submit" color="secondary" variant="contained">Nộp hồ sơ</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>

      <Dialog fullWidth maxWidth="xs" open={!loading && (isNotAvalaible || warningOpen)}>
        <DialogTitle style={{ padding: "4px 12px 4px 24px" }}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant="h5"><b>Thông báo</b></Typography>
            {!isNotAvalaible && (
              <IconButton onClick={() => setWarningOpen(false)}><Close /></IconButton>
            )}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" width="100%" marginBottom={4}>
            <WarningRounded style={{ margin: "auto", color: "#4da6ff", fontSize: 72 }} />
          </Box>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            {isNotAvalaible ? (
              <>
                {isNotAvalaible}
                <br />
                <b>Giải thưởng Khoa học công nghệ <br /> Quả Cầu Vàng năm {openSeason?.year}</b>
              </>
            ) : (
              <b>
                {application?.status !== "in_review"
                ? "Hãy hoàn thành Bảng thành tích cá nhân trước khi đăng ký hồ sơ!"
                : "Hồ sơ đã được nộp trên hệ thống!"}
              </b>
            )}
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: "8px 24px" }}>
          {isNotAvalaible ? (
            <Button fullWidth color="secondary" variant="contained" href={ROUTER.HOME}>
              Về trang chủ
            </Button>
          ) : (
            <>
              {application?.status !== "in_review" ? (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button fullWidth variant="outlined" onClick={() => setWarningOpen(false)}>Đăng ký hồ sơ</Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={() => navigate(ROUTER.QCV.THANH_TICH_CA_NHAN.BANG_SANG_CHE)}
                    >
                      Chỉnh sửa thành tích
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      href={ROUTER.QCV.HOME}
                      variant="outlined"
                      startIcon={<Home />}
                      onClick={() => setWarningOpen(false)}
                    >
                      Trang chủ
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      startIcon={<Edit />}
                      style={{ float: "right" }}
                      onClick={() => setWarningOpen(false)}
                    >
                      Chỉnh sửa hồ sơ
                    </Button>
                  </Grid>
                </Grid>
              )}
              </>
          )}
        </DialogActions>
      </Dialog>

      <PreviewModal open={!!preview} url={preview} handleClose={() => setPreview(null)} />
    </>
  )
  
}

export default DangKyHoSoUpdate;
