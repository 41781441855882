import React from 'react';

import { ROUTER } from '../../constants';

import Home from '../Home';
import TongQuan from '../GioiThieu';

import DangKy from '../../containers/AppLayout/DangKy';
import DangNhap from '../../containers/AppLayout/DangNhap';
import DoiMatKhau from '../../containers/AppLayout/DoiMatKhau';
import ThongTinCaNhan from '../../containers/AppLayout/ThongTinCaNhan';
import HuongDanSuDung from '../../containers/QCV/HuongDanSuDung/HuongDanSuDung';
import QuanLyNamToChuc from '../../containers/AppLayout/QuanLyNamToChuc';
import QuanLyNguoiDung from '../../containers/AppLayout/QuanLyNguoiDung';
import DanhSachHoSoCaNhan from '../../containers/AppLayout/DanhSachHoSo';

import HoSoQCV from '../QCV/HoSo';
import GioiThieuQCV from '../QCV/GioiThieu';
import DanhSachHoSo from '../QCV/DanhSachHoSo';
import DangKyHoSoQCV from '../QCV/DangKyHoSo';
import CauHinhHeThong from '../QCV/CauHinhHeThong';
import DanhSachThanhTich from '../QCV/DanhSachThanhTich';

import HoSoXHNV from '../XHNV/HoSo';
import GioiThieuXHNV from '../XHNV/GioiThieu';
import DangKyHoSoXHNV from '../XHNV/DangKyHoSo';

import GioiThieuPTNS from '../PTNS/GioiThieu';
import DangKyHoSoPTNS from '../PTNS/DangKyHoSo';
import BinhChonV2 from '../QCV/BinhChonV2/BinhChonV2';
import KetQuaBinhChon from '../../containers/QCV/BinhChon/KetQuaBinhChon/KetQuaBinhChon';
// import DuyetThanhTichCaNhan from '../QCV/DuyetThanhTichCaNhan';


const ROUTER_CONFIG = {
  BASE: [
    { path: ROUTER.HOME, element: <Home />, disableHeader: true, maxWidth: 'xl', authRequired: false },
    { path: ROUTER.DANG_KY, element: <DangKy />, disableHeader: true, maxWidth: 'xl', authRequired: false },
    { path: ROUTER.DANG_NHAP, element: <DangNhap />, disableHeader: true, maxWidth: 'md', authRequired: false },
    { path: ROUTER.TONG_QUAN, element: <TongQuan />, disableHeader: true, maxWidth: 'lg', authRequired: false },
    { path: ROUTER.DOI_MAT_KHAU, element: <DoiMatKhau />, maxWidth: 'md' },
    { path: ROUTER.THONG_TIN_CA_NHAN, element: <ThongTinCaNhan /> },
    { path: ROUTER.DANH_SACH_HO_SO_CA_NHAN, element: <DanhSachHoSoCaNhan />, maxWidth: 'lg' },
    { path: ROUTER.QCV.HUONG_DAN_SU_DUNG, element: <HuongDanSuDung />, maxWidth: 'md', bgColor: '#E8F0FD' },
    { path: ROUTER.DANH_SACH_HO_SO, element: <DanhSachHoSo />, maxWidth: 'lg' },
    { path: ROUTER.QCV.BINH_CHON, element: <BinhChonV2 />, maxWidth: 'lg' },
  ],
  ADMIN: [
    { path: ROUTER.CAU_HINH, element: <CauHinhHeThong />, maxWidth: 'lg', adminRoleRequired: true },
    { path: ROUTER.QUAN_LY_NGUOI_DUNG, element: <QuanLyNguoiDung />, maxWidth: 'lg', adminRoleRequired: true },
    { path: ROUTER.QUAN_LY_NAM_TO_CHUC, element: <QuanLyNamToChuc />, maxWidth: 'md', adminRoleRequired: true },
    { path: ROUTER.QCV.KET_QUA_BINH_CHON, element: <KetQuaBinhChon />, maxWidth: 'xl', adminRoleRequired: true },
  ],
  QCV: [
    { path: ROUTER.GIOI_THIEU, element: <GioiThieuQCV />, authRequired: false },
    { path: ROUTER.DANG_KY_HO_SO, element: <DangKyHoSoQCV />, maxWidth: 'md', bgColor: '#E8F0FD' },
    { path: ROUTER.QCV.DUYET_THANH_TICH + '/:id', element: <DanhSachThanhTich />, maxWidth: 'lg', exact: false },
    { path: ROUTER.HO_SO + '/:id', element: <HoSoQCV />, maxWidth: 'lg', exact: false },
  ],
  XHNV: [
    { path: ROUTER.GIOI_THIEU, element: <GioiThieuXHNV />, authRequired: false },
    { path: ROUTER.DANG_KY_HO_SO, element: <DangKyHoSoXHNV />, maxWidth: 'md', bgColor: '#E8F0FD' },
    { path: ROUTER.HO_SO + '/:id', element: <HoSoXHNV />, maxWidth: 'lg', exact: false },
  ],
  PTNS: [
    { path: ROUTER.GIOI_THIEU, element: <GioiThieuPTNS />, authRequired: false },
    { path: ROUTER.DANG_KY_HO_SO, element: <DangKyHoSoPTNS />, maxWidth: 'lg', bgColor: '#E8F0FD' },
  ]
}

export default ROUTER_CONFIG;
