import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Paper, CircularProgress } from '@material-ui/core';

import HoSoNote from '../../../containers/QCV/HoSo/HoSoNote';
import HoSoViewAdmin from '../../../containers/XHNV/HoSo/HoSoViewAdmin';

export default function HoSo() {
	const role = useSelector(state => state.userReducer.user?.role);
	const loading = useSelector(state => state.formReducer.loading);
	const isSuperAdmin = role === 'super_admin' || role?.includes('qcv_admin_');

	if (!role || loading) return <CircularProgress style={{ marginTop: 128 }} />;

	return (
		<>
			<Grid container justifyContent='center'>
				<Grid item xs={12} md={9}>
					<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
						<Paper className='paper' style={{ marginTop: 32, marginBottom: 16 }}>
							<HoSoViewAdmin isSuperAdmin={isSuperAdmin} />
						</Paper>
						<Paper className='paper' style={{ margin: '32px 0' }}>
							<HoSoNote />
						</Paper>
					</div>
				</Grid>
			</Grid>
		</>
	)
}