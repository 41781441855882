import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import DuAnTable from '../../DuAnNghienCuu/DuAnTable';
import BaoCaoTable from '../../BaoCao/BaoCaoTable';
import DaoTaoTable from '../../DaoTao/DaoTaoTable';
import BaiBaoTable from '../../BaiBao/BaiBaoTable';
import SangCheTable from '../../BangSangChe/SangCheTable';
import BangSachTable from '../../SachChuyenKhao/BangSachTable';
import GiaiThuongTable from '../../GiaiThuong/GiaiThuongTable';
import DoanhNghiepTable from '../../DoanhNghiep/DoanhNghiepTable';
import { ARCHIVEMENT_TYPE, KEYS } from '../../../../constants/enums';

function DanhSachThanhTichTable(props) {
	const form = useSelector(state => state.formReducer.focusForm);

  const tableComponent = useMemo(() => {
    if (props.value && form) {
      var rows = null;
      switch (props.value) {
        case ARCHIVEMENT_TYPE.SOLUTION:
          rows = form.formSolution.map((e) => ({
            ...e.solution,
            formSolutionId: e.id,
            applicationFormId: e.applicationFormId,
            score: e.score,
            note: e.note,
            thanhTichAccepted: e.accepted,
          }));
          return <SangCheTable type='accept' rows={rows} />;
        case ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL:
        case ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC: {
          const formScientificResearch = form.formScientificResearch.map((e) => ({
            ...e.scientificResearch,
            formScientificResearchId: e.id,
            applicationFormId: e.applicationFormId,
            score: e.score,
            note: e.note,
            thanhTichAccepted: e.accepted,
          }));
          const scientificResearchForm = (props.value === ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL)
            ? KEYS.INTERNATIONAL
            : KEYS.DOMESTIC
          rows = formScientificResearch.filter((e) => e.filterType === scientificResearchForm);
          return <BaiBaoTable form={scientificResearchForm} type='accept' rows={rows} />
        }
        case ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT:
          rows = form.formScientificReport.map((e) => ({
            ...e.scientificReport,
            formScientificReportId: e.id,
            applicationFormId: e.applicationFormId,
            score: e.score,
            note: e.note,
            thanhTichAccepted: e.accepted,
          }));
          return <BaoCaoTable type='accept' rows={rows} />
        case ARCHIVEMENT_TYPE.MONOGRAPH:
          rows = form.formMonograph.map((e) => ({
            ...e.monograph,
            formMonographId: e.id,
            applicationFormId: e.applicationFormId,
            score: e.score,
            note: e.note,
            thanhTichAccepted: e.accepted,
          }));
          return <BangSachTable type='accept' rows={rows} />
        case ARCHIVEMENT_TYPE.RESEARCH_PROJECT:
          rows = form.formResearchProject.map((e) => ({
            ...e.researchProject,
            formResearchProjectId: e.id,
            applicationFormId: e.applicationFormId,
            score: e.score,
            note: e.note,
            thanhTichAccepted: e.accepted,
          }));
          return <DuAnTable type='accept' rows={rows} />
        case ARCHIVEMENT_TYPE.REWARD:
          rows = form.formReward.map((e) => ({
            ...e.reward,
            birth: form?.profile?.birth,
            formRewardId: e.id,
            applicationFormId: e.applicationFormId,
            score: e.score,
            note: e.note,
            thanhTichAccepted: e.accepted,
          }));
          return <GiaiThuongTable type='accept' rows={rows} />
        case ARCHIVEMENT_TYPE.HR_DEVELOPMENT:
          rows = form.formHrDevelopment.map((e) => ({
            ...e.hrDevelopment,
            formHrDevelopmentId: e.id,
            applicationFormId: e.applicationFormId,
            score: e.score,
            note: e.note,
            thanhTichAccepted: e.accepted,
          }));
          return <DaoTaoTable type='accept' rows={rows} tableType='daoTao' />
        case ARCHIVEMENT_TYPE.TECH_COMPANY:
          rows = form.formTechCompany.map((e) => ({
            ...e.techCompany,
            formTechCompanyId: e.id,
            applicationFormId: e.applicationFormId,
            score: e.score,
            note: e.note,
            thanhTichAccepted: e.accepted,
          }));
          return <DoanhNghiepTable type='accept' rows={rows} />
        default:
          return null;
      }
    } else {
      return null;
    }
  }, [props.value, form]);

	return tableComponent;
}

export default DanhSachThanhTichTable;
