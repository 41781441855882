import {
  Box,
  Card,
  Grid,
  Button,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  OutlinedInput,
} from '@material-ui/core';
import {
  Add,
  Edit,
  Info,
  Delete,
  Drafts,
  Visibility,
  CheckCircle,
} from '@material-ui/icons';
import React from 'react';

import theme from '../../../theme';
import KPTextfield from '../../../components/KPTComponents/KPTextfield';
import KPTimepicker from '../../../components/KPTComponents/KPTimepicker';

import { useDispatch } from 'react-redux';
import { userActions } from '../../../actions';

export default function InputActives(props) {
  const dispatch = useDispatch();
  const [removeFileValue, setRemoveFileValue] = React.useState([]);

  const disabled = React.useMemo(() => props.newFileLength >= 5, [props.newFileLength]);
  // console.log( props.newFileLength);

  return (
    <>
      <Box padding='2px 4px 2px 16px' display='flex' alignItems='center'>
        <Typography variant='h6' style={{ flexGrow: 1 }}>
          <b>{props.label}</b>
        </Typography>
        {disabled ? (
          <Tooltip
            title={
              <>
                <Box display='flex' alignItems='center'>
                  <Info style={{ fontSize: 11, marginRight: 4 }} />
                  <Typography style={{ fontSize: 11}}>
                    Chỉ được upload tối đa 5 file minh chứng một lần!
                  </Typography>
                </Box>
                <Box display='flex' alignItems='center'>
                  <CheckCircle style={{ fontSize: 11, marginRight: 4 }} />
                  <Typography style={{ fontSize: 11}}>
                    Vui lòng Lưu bản nháp sau đó upload thêm file minh chứng
                  </Typography>
                </Box>
              </>
            }
          >
            <span>
              <IconButton
                color='primary'
                disabled={disabled}
                onClick={() => props.setValues([...props.values, {}])}
              >
                <Add />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <IconButton
            color='primary'
            disabled={disabled}
            onClick={() => props.setValues([...props.values, {}])}
          >
            <Add />
          </IconButton>
        )}
      </Box>
      <Divider style={{ width: 'calc(100% + 32px)', margin: '0 -16px' }} />
   
      <Grid container spacing={1} style={{ padding: '8px 16px' }}>
        {(props.values?.length > 0) ? props.values?.map((value, index) => (
          <React.Fragment key={`value-${index}`}>
            {props.fields?.map((field) => (
              <React.Fragment key={`${field.key}-${index}`}>
                {field?.type == 'file' ? (
                  <Grid item xs={field.width} style={{ margin: '4px 0' }}>
                    <Typography className='kpt-textfield-label'>
                      {field.label}
                      <span style={{ color: theme.palette.secondary.main }}>*</span>:
                    </Typography>
                    {(() => {
                      const file = field.key;
                      var value = props.values[index][file];
                      if (typeof value !== 'string') value = "";

                      return (
                        <>
                          {value ? (
                            <>
                              <OutlinedInput
                                id={file}
                                fullWidth
                                size='small'
                                value={value.replace(' ', '_').slice(value.indexOf('_') + 1)}
                                variant='outlined'
                                style={{
                                  paddingRight: '0',
                                  backgroundColor: 'white',
                                  height: '35px',
                                }}
                                endAdornment={
                                  <Grid
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-around',
                                      alignItems: 'center',
                                      // padding: '0 12px',
                                    }}
                                  >
                                    <Divider orientation='vertical' style={{ height: 35 }} />
                                    <IconButton
                                      color='primary'
                                      title='Chi tiết minh chứng'
                                      edge='center'
                                      onClick={() => {
                                        props.setPreview(value);
                                      }}
                                    >
                                      <Visibility />
                                    </IconButton>
                                    <Divider orientation='vertical' style={{ height: 35 }} />
                                    <IconButton
                                      title='Chỉnh sửa minh chứng'
                                      edge='center'
                                      onClick={() => {
                                        // props.handleFileChange(null, `${props.id}.${index}.${field.key}`);
                                        props.handleChangeForm(`${props.id}.${index}.${file}`, null, 'removeFile');
                                        setRemoveFileValue([
                                          ...removeFileValue,
                                          {
                                            index,
                                            [file]: props.values[index][file],
                                          },
                                        ]);
                                      }}
                                    >
                                      <Edit />
                                    </IconButton>
                                  </Grid>
                                }
                              />
                            </>
                          ) : (
                            <Card
                              style={{
                                height: 22,
                                borderColor: '#b3b9c3',
                                padding: '5.5px 8px',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <input
                                type='file'
                                // onChange={(e) => {
                                //   props.handleFileChange(e.target.files[0], `${props.id}.${index}.${field.key}`);
                                // }}
                                onChange={(e) => {
                                  if (e.target.files[0]?.size > 10485760) {
                                    e.target.value = null;
                                    dispatch(userActions.notification({
                                      message: 'Dung lượng vượt quá hơn 10MB',
                                      type: 'error'
                                    }));
                                  } else {
                                    props.handleFileChange(e.target.files[0], `${props.id}.${index}.${field.key}`);
                                  }
                                }}
                              />
                              {/* <CardActions>
                                {removeFileValue.find(O
                                  (elm) => elm?.index === index
                                ) && (
                                  <Tooltip title="Hủy chọn">
                                    <IconButton
                                      edge="end"
                                      onClick={(e) => {
                                        e.target.value = removeFileValue.find(
                                          (elm) => elm?.index === index
                                        )[file];
                                        props.handleChangeForm(
                                          `${props.id}.${index}.${file}`,
                                          e
                                        );
                                        // props.handleFileChange(null, `${props.id}.${index}.${field.key}`);
                                        // console.log(e.target.file)
                                      }}
                                    >
                                      <Close />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </CardActions> */}
                            </Card>
                          )}
                        </>
                      );
                    })()}
                  </Grid>
                ) : (
                  <>
                    {field.type === "date" ? (
                      <Grid item xs={field.width} style={{ margin: "4px 0" }}>
                        <KPTimepicker
                          {...field}
                          id={`${props.id}.${index}.${field.key}`}
                          value={value[field.key] || 'dd/mm/yyyy'}
                          setValue={(e) => props.handleChangeForm(
                            `${props.id}.${index}.${field.key}`, e, 'date', props.values, props.setValues,
                          )}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={field.width} style={{ margin: '4px 0' }}>
                        <KPTextfield
                          {...field}
                          id={`${props.id}.${index}.${field.key}`}
                          value={value[field.key]}
                          label={field.label}
                          options={field.options}
                          handleChangeForm={(name, event, type) => props.handleChangeForm(
                            name, event, type, props.values, props.setValues
                          )}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
            <Grid item xs={1}>
              <Button
                fullWidth
                color='secondary'
                variant='outlined'
                style={{ marginTop: 33, height: 37, backgroundColor: '#fff' }}
                onClick={() => {
                  props.setValues(props.values.filter((_, elmId) => index !== elmId));
                }}
              >
                <Delete />
              </Button>
            </Grid>
          </React.Fragment>
          ))
        : (
          <Box width='100%' padding={1} textAlign='center'>
            <Drafts color='disabled' style={{ fontSize: 128 }} />
            <Typography variant='h5' style={{ color: 'rgba(0, 0, 0, 0.26)' }}>Không có dữ liệu</Typography>
          </Box>
        )}
      </Grid>
    </>
  );
}
