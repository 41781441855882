import { HEADERS } from '.';

export const USER_API = {
  login: () => ({
    endPoint: '/api/v1/user/login',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
  register: () => ({
    endPoint: '/api/v1/user/create',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
  loginWithToken: () => ({
    endPoint: '/api/v1/user/login/jwt',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
  changePassword: () => ({
    endPoint: '/api/v1/user/modify/password',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
  resetPassword: (id) => ({
    endPoint: `/api/v1/user/reset/${id}`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
  resetByEmail: (top, bot) => ({
    endPoint: `/api/v1/user/resetByEmail?email=${top}%40${bot}`,
    headers: HEADERS.DEFAULT_HEADER,
    method: 'PUT',
  }),
  getAllUser: () => ({
    endPoint: `/api/v1/user/all`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  modifyRole: () => ({
    endPoint: '/api/v1/user/modify/role',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
}


export const USER_TYPE = {
  LOGOUT: 'LOGOUT',
  NOTIFICATION: 'NOTIFICATION',
  LOGIN_WITH_TOKEN: 'LOGIN_WITH_TOKEN',

  LOGIN_LOADING: 'LOGIN_LOADING',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  REGISTER_LOADING: 'REGISTER_LOADING',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  CHANGE_PASSWORD_LOADING: 'CHANGE_PASSWORD_LOADING',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  RESET_BY_EMAIL_LOADING: 'RESET_BY_EMAIL_LOADING',
  RESET_BY_EMAIL_SUCCESS: 'RESET_BY_EMAIL_SUCCESS',
  RESET_BY_EMAIL_FAILURE: 'RESET_BY_EMAIL_FAILURE',

  GET_ALL_USER_LOADING: 'GET_ALL_USER_LOADING',
  GET_ALL_USER_SUCCESS: 'GET_ALL_USER_SUCCESS',
  GET_ALL_USER_FAILURE: 'GET_ALL_USER_FAILURE',

  MODIFY_ROLE_LOADING: 'MODIFY_ROLE_LOADING',
  MODIFY_ROLE_SUCCESS: 'MODIFY_ROLE_SUCCESS',
  MODIFY_ROLE_FAILURE: 'MODIFY_ROLE_FAILURE',
};

export const USER_CONSTANTS ={
  roleOptions : [
    { key: 'candidate', title: 'Ứng cử viên', value: 'candidate' },
    { key: 'dev', title: 'Tester hệ thống', value: 'dev' },
    { key: 'council', title: 'Hội đồng bình chọn (Cả 2 giải thưởng)', value: 'council' },
    { key: 'council_qcv', title: 'Hội đồng bình chọn QCV', value: 'council_qcv' },
    { key: 'council_ptns', title: 'Hội đồng bình chọn PTNS', value: 'council_ptns' },
    { key: 'super_admin', title: 'Quản trị viên hệ thống', value: 'super_admin' },
    { key: 'ptns_admin', title: 'Quản trị viên PTNS', value: 'ptns_admin' },
    {
      key: 'qcv_admin_cnsh',
      title: 'Hội đồng Công nghệ sinh học',
      value: 'qcv_admin_cnsh',
    },
    {
      key: 'qcv_admin_cnyd',
      title: 'Hội đồng Công nghệ y dược',
      value: 'qcv_admin_cnyd',
    },
    {
      key: 'qcv_admin_cntt',
      title: 'Hội đồng Công nghệ thông tin, chuyển đổi số và tự động hóa',
      value: 'qcv_admin_cntt',
    },
    {
      key: 'qcv_admin_cnmt',
      title: 'Hội đồng Công nghệ môi trường',
      value: 'qcv_admin_cnmt',
    },
    {
      key: 'qcv_admin_cnvlm',
      title: 'Hội đồng Công nghệ vật liệu mới',
      value: 'qcv_admin_cnvlm',
    },
  ]
}
