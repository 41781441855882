import { useState, useEffect } from 'react';
import { APP_SCENE, CATEGORY } from '../constants/enums';

const DEFAULT = {
  isQuaCauVang: false,
  isXaHoiNhanVan: false,
  isPhanThuongNuSinh: false,
};

export const useScene = () => {
  const [scene, setScene] = useState({ ...DEFAULT });

  useEffect(() => {
    const newScene = { ...DEFAULT };

    if (APP_SCENE === CATEGORY.QCV) {
      newScene.isQuaCauVang = true;
    } else if (APP_SCENE === CATEGORY.XHNV) {
      newScene.isXaHoiNhanVan = true;
    } else if (APP_SCENE === CATEGORY.PTNS) {
      newScene.isPhanThuongNuSinh = true;
    }

    setScene(newScene);
  }, [process.env.REACT_APP_SCENE]);

  return scene;
}