import React from 'react';
import { Link } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { qcvEnums } from '../../constants/qcv.enums';
import { ARCHIVEMENT_TYPE, KEYS } from '../../constants/enums';
import { monographActions, reportsActions, researchActions, researchProjectActions, rewardActions, solutionActions } from '../../actions';
import { FORM_CONSTANTS, MONOGRAPHS_CONSTANTS, REPORTS_CONSTANTS, RESEARCH_CONSTANTS, RESEARCHPROJECTS_CONSTANTS, REWARD_CONSTANTS } from '../../constants';

export const columnRender = (e, setDialog, setPreviewFile) => {
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    switch (e.type) {
      case ARCHIVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC:
      case ARCHIVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL:
        return monographActions.deleteMonograph(id);
      case ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT:
      case ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC:
      case ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL:
        return reportsActions.deleteReport(id);
      case ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC:
      case ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL:
        return researchActions.deleteResearch(id);
      case ARCHIVEMENT_TYPE.RESEARCH_PROJECT:
        return researchProjectActions.deleteResearchProject(id);
      case ARCHIVEMENT_TYPE.REWARD:
      case ARCHIVEMENT_TYPE.REWARD + KEYS.HTQT:
      case ARCHIVEMENT_TYPE.REWARD + KEYS.HTQG:
      case ARCHIVEMENT_TYPE.REWARD + KEYS.HTCS:
      case ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCB:
      case ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCT:
        return rewardActions.deleteReward(id);
      case ARCHIVEMENT_TYPE.SOLUTION:
        return solutionActions.deleteSolution(id);
      default: break;
    }
  }

  var columns;
  switch (e.type) {
    case ARCHIVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC:
    case ARCHIVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL:
    case ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC:
    case ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL:
    case ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC:
    case ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL:
      columns = [
        { title: <b>Nội dung</b>, dataIndex: 'title', width: '30%' },
        {
          title: <b>Phân loại</b>,
          dataIndex: 'type',
          width: '25%',
          render: (value) => [
            ...REPORTS_CONSTANTS.TYPE_OPTIONS.ALL,
            ...RESEARCH_CONSTANTS.TYPE_OPTIONS.DOMESTIC,
            ...RESEARCH_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL,
            ...MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.DOMESTIC,
            ...MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL,
            ...RESEARCHPROJECTS_CONSTANTS.TYPO_OPTIONS,
          ].find((e) => e.value === value)?.title,
        },
        { title: <b>Số tác giả</b>, dataIndex: 'numberAuthor', width: '15%' },
        {
          title: <b>Vai trò</b>,
          dataIndex: 'role',
          width: '30%',
          render: (value) => RESEARCH_CONSTANTS.ROLE_OPTIONS.find((e) => e.value === value)?.title
        },
      ];
      break;
    
    case ARCHIVEMENT_TYPE.RESEARCH_PROJECT:
      columns = [
        { title: <b>Nội dung</b>, dataIndex: 'title', width: '30%' },
        {
          title: <b>Phân loại</b>,
          dataIndex: 'level',
          width: '25%',
          render: (value) => RESEARCHPROJECTS_CONSTANTS.LEVEL_OPTIONS.find((e) => e.value === value)?.title,
        },
        {
          title: <b>Vai trò</b>,
          dataIndex: 'type',
          width: '30%',
          render: (value) => RESEARCHPROJECTS_CONSTANTS.TYPO_OPTIONS.find((e) => e.value === value)?.title
        },
      ];
      break;

    case ARCHIVEMENT_TYPE.REWARD + KEYS.HTQT:
    case ARCHIVEMENT_TYPE.REWARD + KEYS.HTQG:
    case ARCHIVEMENT_TYPE.REWARD + KEYS.HTCS:
      columns = [
        { title: <b>Nội dung</b>, dataIndex: 'title', width: '30%' },
        {
          title: <b>Phân loại</b>,
          dataIndex: 'type',
          width: '55%',
          render: (value) => REWARD_CONSTANTS.TYPE_OPTIONS[e.type.replace(ARCHIVEMENT_TYPE.REWARD, '')].find((e) => e.value === value)?.title,
        },
      ];
      break;

    case ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCB:
    case ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCT:
      columns = [
        { title: <b>Nội dung</b>, dataIndex: 'title', width: '30%' },
        {
          title: <b>Phân loại</b>,
          dataIndex: 'type',
          width: '55%',
          render: (value) => REWARD_CONSTANTS.TYPE_OPTIONS[e.type.replace(ARCHIVEMENT_TYPE.REWARD, '')].find((e) => e.value === value)?.title,
        },
        {
          title: <b>Vai trò</b>,
          dataIndex: 'role',
          width: '30%',
          render: (value) => FORM_CONSTANTS.ROLE_OPTIONS.slice(0, 2).find((e) => e.value === value)?.title,
        },
      ];
      break;
  
    default:
      columns = [
        { title: <b>Nội dung</b>, dataIndex: 'title', width: '30%' },
        {
          title: <b>Phân loại</b>,
          dataIndex: 'type',
          width: '20%',
          render: (value) => qcvEnums.TYPE_OPTIONS?.[value],
        },
        { title: <b>Số tác giả</b>, dataIndex: 'numberAuthor', width: '15%' },
        {
          title: <b>Vai trò</b>,
          dataIndex: 'role',
          width: '20%',
          render: (value) => {
            if (value === 'main' && (e?.type === 'research' || e?.type === 'report'))
              return 'Tác giả chính (hoặc tác giả liên hệ)';
            // return roleOptions.find((e) => e.value === value)?.title;
          },
        },
      ];
  }

  columns.push({
    title: <b>Tác vụ</b>,
    dataIndex: 'link',
    width: '15%',
    render: (value, row) => (
      <>
        {
          (row?.link.includes('/storage/awardRegister/profile') ||
            row?.preview?.includes('/storage/awardRegister/profile')) && (
            <Link
              variant='body2'
              style={{ display: 'block' }}
              className='actions-button'
              onClick={() => {
                setPreviewFile(row?.link || row?.preview);
              }}
            >
              Minh chứng
            </Link>
          )
        }
        {row?.link?.includes('doi') && (
          <a target='_blank' rel='noreferrer' href={row.link}>
            Link DOI
          </a>
        )}
        <Link
          color='primary'
          variant='body2'
          style={{ display: 'block' }}
          className='actions-button'
          onClick={() => {
            setDialog({
              data: row,
              type: e.type,
              title: e.title,
              actionType: 'edit'
            });
          }}
        >
          Chi tiết
        </Link>
        <Link
          className='actions-button'
          color='error'
          style={{ display: 'block' }}
          onClick={() => dispatch(handleDelete(row.id)) }
        >
          Xóa
        </Link>
      </>
    ),
  });

  return columns;
};
