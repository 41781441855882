export * from './api';
export * from './doi';
export * from './autoScore';
export * from './dateFormatter';
export * from './exportFormExcel';
export * from './exportToXlsxPTNS';

export const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getWordCount(htmlData) {
    if (htmlData === '' || htmlData === ' ' || !htmlData) return 0;
    return htmlData.replace(/<(?:.|\s)*?>/g, '').split(' ').length;    
}
