import React from "react";
import dayjs from "dayjs";
import noImage from "../../../../assets/image/app/no-image.png";

import { Box, Grid, Typography } from "@material-ui/core";
import { FORM_CONSTANTS } from "../../../../constants";


const HosoProfile = ({applicationDetail}) => {

  const {
    FIELD_QCV_OPTIONS,
    GENDER_OPTIONS,
  } = FORM_CONSTANTS;

  const profileList = [
    {
      key: 'birthday',
      label: 'Ngày sinh',
      value: dayjs(applicationDetail?.profile?.birth).format('DD/MM/YYYY'),
    },
    {
      key: 'ethnic',
      label: 'Dân tộc',
      value: applicationDetail?.profile?.ethnic,
    },
    {
      key: 'gender',
      label: 'Giới tính',
      value: GENDER_OPTIONS.find((e) => e.value === applicationDetail?.profile?.gender)?.title,
    },
    {
      key: 'phone',
      label: 'Điện thoại',
      value: applicationDetail?.profile?.phone,
    },
    { key: 'email', label: 'Email', value: applicationDetail?.profile?.email },
    {
      key: 'facebook',
      label: 'Website/facebook',
      value: (
        <a target='_blank' rel='noreferrer' href={applicationDetail?.profile?.website}>
          {applicationDetail?.profile?.website}
        </a>
      ),
    },
  ];

  return (
    <>
      <Grid item xs={12}>
        <Box display='flex'>
          <Box display='flex'>
            <img
              src={
                applicationDetail?.profile?.photoUrl
                  ? `${process.env.REACT_APP_DOMAIN}${applicationDetail?.profile?.photoUrl}`
                  : noImage
              }
              style={{ width: 195, height: 260, objectFit: 'cover' }}
            />
          </Box>
          <Box display='flex' flexGrow={1} paddingLeft={4}>
            <Box width='100%'>
              <Typography variant='h3'>
                <b>{applicationDetail?.profile?.name}</b>
              </Typography>
              <Typography variant='h6'>
                <b>Lĩnh vực: </b>
                {FIELD_QCV_OPTIONS.find((e) => e.value === applicationDetail?.field)?.title}
              </Typography>
              {profileList.map((e, index) => (
                <Typography key={index}>
                  <b>{e.label}: </b>
                  <span>{e.value}</span>
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
        <Box bgcolor='black' width='100%' height={4} />
      </Grid>
    </>
  )
}

export default HosoProfile;