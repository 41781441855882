/* eslint-disable */
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import * as ExcelJS from 'exceljs';

import { qcvEnums } from '../constants/qcv.enums';
import { ptnsEnums } from '../constants/ptns.enums';
import { fileRender } from './photoRender';

const setCellValue = (cell, properties) => {
  try {
    for (const item in properties) cell[item] = properties[item];
  } catch (e) {
    console.log(e)
  }
}

const convertNumToString= (num) => {
  var _num = num.toString();
  while (_num.lengh < 2) _num = '0' + _num;
  return num;
}

const getValue = (s) => s[0].toLowerCase() + s.slice(1);

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const LIST_REWARD_TYPE = ['g1', 'g2', 'g3-1', 'g3-2', 'g4', 'g5', 'nstb-g1', 'nstb-g2', 'nstb-g3'];
const LIST_RESEARCH_TYPE = ['nstb-n1', 'nstb-n2', 'nstb-n3', 'nstb-n4', 'nstb-n5', 'nstb-n6', 'nstb-n7'];


export const exportToXlsxPTNS = (_formList, field, fieldName) => {
  const workbook = new ExcelJS.Workbook();
  workbook.properties.date1904 = true;
  workbook.views = [{
    x: 0, y: 0, width: 10000, height: 20000,
    firstSheet: 0, activeTab: 0, visibility: 'visible'
  }];

  const worksheet = workbook.addWorksheet(fieldName);
  worksheet.columns = qcvEnums.EXPORT_COLUMNS;

  // Header
  worksheet.mergeCells('A1:H1');
  setCellValue(worksheet.getCell('A1'), {
    font: { size: 14, bold: true },
    alignment: { horizontal: 'center' },
    value: 'HỒ SƠ XÉT CHỌN GIẢI THƯỞNG NỮ SINH KHCN 2023'
  });

  worksheet.addRow({ name: `LĨNH VỰC: ${fieldName}` });
  for (var i = 2; i <= 5; i++) worksheet.mergeCells(`A${i}:H${i}`);

  worksheet.addRow(qcvEnums.EXPORT_COLUMNS.map((e) => e.title));

  _formList.forEach((form) => {
    form.formReward.forEach((r) => {
      console.log(r.reward.type)
    })
  });

  const formList = _formList.map((form) => ({
    ...form,
    formScientificReward: form.formReward.filter((r) => (
      LIST_RESEARCH_TYPE.find((e) => r.reward.type === e)
    )).map((e) => ({ ...e, reward: null, scientificReward: e.reward })),
    formStudentReward: form.formReward.filter((r) => (
      LIST_REWARD_TYPE.find((e) => r.reward.type === e)
    )).map((e) => ({ ...e, reward: null, studentReward: e.reward })),
  }));

  formList.sort((a, b) => a.realID - b.realID).map((form) => {
    const startRow = worksheet.rowCount;

    worksheet.addRow({
      name: `${field?.toUpperCase() || 'PTNS'}${convertNumToString(form.realID)} - ${form.profile.name}`,
      score: form?.scoreA + form?.scoreB + form?.scoreC + form?.scoreD + form?.scoreE + form?.scoreF + form?.scoreG,
      linkMinhChung: ''
    });

    worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
    worksheet.mergeCells(`G${worksheet.rowCount}:H${worksheet.rowCount}`);

    ["A", "F", "G"].forEach((key) => {
      setCellValue(worksheet.getCell(`${key}${worksheet.rowCount}`), {
        font: { bold: true },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF00' },
          bgColor: { argb: 'FFFF00' }
        }
      })
    });

    worksheet.addRow({
      name: '', type: '', numberAuthor: '', isMain: '', linkMinhChung: '', note: '',
      title: 'Thành tích khoa học công nghệ',
      score: form.scoreA,
    },);

    worksheet.getCell(`B${worksheet.rowCount}`).style = {
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' }, bgColor: { argb: 'FFFFFF' } },
      font: { bold: true, color: { argb: 'FFFF0000' } },
    }

    ptnsEnums.THANH_TICH_LIST?.forEach((key) => {
      const value = getValue(key.replace('form', ''));

      var list = form[key]?.map((e) => ({
        ...e?.[value],
        note: e?.note || '',
        score: e?.score || 0,
        accepted: e?.accepted,
        preview: e?.[value]?.preview?.includes('/storage/awardRegister/') ? `${process.env.REACT_APP_DOMAIN}${e?.[value]?.preview}` : e?.[value]?.preview,
        link: e?.[value]?.link?.includes('/storage/awardRegister/') ? `${process.env.REACT_APP_DOMAIN}${e?.[value]?.link}` : e?.[value]?.link,
      }));

      if (list?.length > 0) {
        var rows = [];
        switch (value) {
          case 'solution':
            var patentNum = 0, patentVnNum = 0, solutionNum = 0, score = 0;
            list.forEach((e) => {
              patentNum += (e.type === 'sc') ? 1 : 0;
              patentVnNum += (e.type === 'scqg') ? 1 : 0;
              solutionNum += (e.type === 'gp') ? 1 : 0;
              score += e.score;
            });

            rows = [
              {
                name: '',
                title: `${list.length} bằng độc quyền sáng chế, giải pháp hữu ích. Trong đó có ` +
                `${patentNum > 0 ? ` ${patentNum} bằng sáng chế quốc tế` : ''}` +
                `${patentVnNum > 0 ? ` ${patentVnNum} bằng độc quyền sáng chế quốc gia` : ''}` +
                `${solutionNum > 0 ? ` ${solutionNum} bằng độc quyền giải pháp hữu ích quốc gia` : ''}`,
                type: '', numberAuthor: '', isMain: '', score: Math.round(score * 100) / 100, linkMinhChung: '', note: '',
              },
              ...list.map((e) => ({
                ...e,
                name: '',
                type: qcvEnums.TYPE_OPTIONS[e.type],
                isMain: '',
                numberAuthor: 0,
                linkMinhChung: e?.link || e?.preview || '',
              }))
            ];
            break;
          case 'scientificResearch':
            score = 0;
            var counter = {
              q1Num: 0, q2Num: 0, q3Num: 0, q4Num: 0, q6Num: 0,
              q1MainNum: 0, q2MainNum: 0, q3MainNum: 0, q4MainNum: 0, q6MainNum: 0,
            };
            list.forEach((e) => {
              counter[`${e.type === 'scopus' ? 'q4' : e.type}Num`]++;
              if (e.role === 'main') counter[`${e.type === 'scopus' ? 'q4' : e.type}MainNum`]++;
              score += e.score;
            });

            rows = [
              {
                name: '', type: '', numberAuthor: '', isMain: '', score: '', linkMinhChung: '', note: '',
                title: (`${counter.q1Num + counter.q2Num + counter.q3Num + counter.q4Num > 0 ? `${counter.q1Num + counter.q2Num + counter.q3Num + counter.q4Num} bài báo khoa học đã công bố trên tạp chí khoa học quốc tế. Trong đó:` : ''}`
                + `${counter.q1Num > 0 ? ` ${counter.q1Num} bài báo thuộc danh mục Q1 (${counter.q1MainNum} bài là tác giả chính),` : ''}`
                + `${counter.q2Num > 0 ? ` ${counter.q2Num} bài báo thuộc danh mục Q2 (${counter.q2MainNum} bài là tác giả chính),` : ''}`
                + `${counter.q3Num > 0 ? ` ${counter.q3Num} bài báo thuộc danh mục Q3 (${counter.q3MainNum} bài là tác giả chính),` : ''}`
                + `${counter.q4Num > 0 ? ` ${counter.q4Num} bài báo thuộc danh mục Q4/Scopus (${counter.q4MainNum} bài là tác giả chính),` : ''}`
                + `${counter.q6Num > 0 ? ` ${counter.q6Num} bài báo khoa học đã công bố trên tạp chí khoa học trong nước (${counter.q6MainNum} bài là tác giả chính).` : ''}`).replace(/.$/,'.'),
              },
              ...list.map((e) => ({
                ...e,
                name: '',
                type: qcvEnums.TYPE_OPTIONS[e.type],
                isMain: '',
                numberAuthor: 0,
                linkMinhChung: e?.link || e?.preview || '',
              }))
            ];
            break;
          case 'scientificReport':
            score = 0;
            counter = {
              q1Num: 0, q2Num: 0, q3Num: 0, q4Num: 0, b1Num: 0, 'b2-1Num': 0, 'b2-2Num': 0, b3Num: 0,
              q1MainNum: 0, q2MainNum: 0, q3MainNum: 0, q4MainNum: 0, b1MainNum: 0, 'b2-1MainNum': 0, 'b2-2MainNum': 0, b3MainNum: 0,
            };
            list.forEach((e) => {
              counter[`${e.type}Num`]++;
              score += e.score;
            });
            rows = [
              {
                name: '', type: '', numberAuthor: '', isMain: '', linkMinhChung: '', note: '',
                title: (`${counter.q1Num + counter.q2Num + counter.q3Num + counter.q4Num + counter.b1Num + counter['b2-1Num'] + counter['b2-2Num'] + counter.b3Num > 0
                    ? `${counter.q1Num + counter.q2Num + counter.q3Num + counter.q4Num + counter.b1Num + counter['b2-1Num'] + counter['b2-2Num'] + counter.b3Num} bài báo khoa học đã công bố trên hội thảo khoa học quốc tế. Trong đó:`
                    : ''}`
                  + `${counter.q1Num > 0 ? ` ${counter.q1Num} bài báo đăng trên hội thảo quốc tế Q1 (${counter.q1MainNum} bài là tác giả chính),` : ''}`
                  + `${counter.q2Num > 0 ? ` ${counter.q2Num} bài báo đăng trên hội thảo quốc tế Q2 (${counter.q2MainNum} bài là tác giả chính),` : ''}`
                  + `${counter.q3Num > 0 ? ` ${counter.q3Num} bài báo đăng trên hội thảo quốc tế Q3 (${counter.q3MainNum} bài là tác giả chính),` : ''}`
                  + `${counter.q4Num > 0 ? ` ${counter.q4Num} bài báo đăng trên hội thảo quốc tế Q4 (${counter.q4MainNum} bài là tác giả chính),` : ''}`).replace(/.$/,'.')
                  + `${counter.b1Num > 0 ? ` ${counter.b1Num} báo cáo khoa học xuất sắc tại hội thảo/hội nghị quốc tế (Oral presentation) (${counter.b1MainNum} bài là tác giả chính),` : ''}`
                  + `${counter['b2-1Num'] > 0 ? ` ${counter['b2-1Num']} poster khoa học xuất sắc tại hội thảo quốc tế (Poster presentation) (${counter['b2-1MainNum']} bài là tác giả chính),` : ''}`
                  + `${counter['b2-2Num'] > 0 ? ` ${counter['b2-2Num']} báo cáo khoa học đăng toàn văn trong kỷ yếu hội thảo/hội nghị quốc tế (${counter['b2-2MainNum']} bài là tác giả chính),` : ''}`
                  + `${counter.b3Num > 0 ? ` ${counter.b3Num} báo cáo khoa học đăng toàn văn trong kỷ yếu hội thảo/hội nghị quốc gia (${counter.b3MainNum} bài là tác giả chính),` : ''}`,
                
                score: Math.round(score * 100) / 100,
              },
              ...list.map((e, id) => ({
                ...e,
                title: `${id + 1}. ${e.title}`,
                name: '',
                type: qcvEnums.TYPE_OPTIONS[e.type],
                isMain: e.role === 'main' ? 1 : 0,
                linkMinhChung: e?.link || e?.preview || '',
              }))
            ];
            break;
          case 'scientificReward':
            score = 0;
            counter = { 'nstb-n1Num': 0, 'nstb-n2Num': 0, 'nstb-n3Num': 0, 'nstb-n4Num': 0, 'nstb-n5Num': 0, 'nstb-n6Num': 0, 'nstb-n7Num': 0 };
            list.forEach((e) => {
              counter[`${e.type}Num`]++;
              score += e.score;
            });
            rows = [
              {
                name: '', type: '', numberAuthor: '', isMain: '', linkMinhChung: '', note: '',
                title: (`${counter['nstb-n1Num'] + counter['nstb-n2Num'] + counter['nstb-n3Num'] + counter['nstb-n4Num'] + counter['nstb-n5Num'] + counter['nstb-n6Num'] + counter['nstb-n7Num'] + counter['nstb-g6Num'] + counter['nstb-g7Num'] + counter['nstb-g8Num'] > 0
                    ? `${counter['nstb-n1Num'] + counter['nstb-n2Num'] + counter['nstb-n3Num'] + counter['nstb-n4Num'] + counter['nstb-n5Num'] + counter['nstb-n6Num'] + counter['nstb-n7Num'] + counter['nstb-g6Num'] + counter['nstb-g7Num'] + counter['nstb-g8Num']} đề tài nghiên cứu khoa học thuộc các ngành xét giải. Trong đó:`
                    : ''}`
                  + `${counter['nstb-n1Num'] > 0 ? ` ${counter['nstb-n1Num']} ${qcvEnums.TYPE_OPTIONS['nstb-n1']},` : ''}`
                  + `${counter['nstb-n2Num'] > 0 ? ` ${counter['nstb-n2Num']} ${qcvEnums.TYPE_OPTIONS['nstb-n2']},` : ''}`
                  + `${counter['nstb-n3Num'] > 0 ? ` ${counter['nstb-n3Num']} ${qcvEnums.TYPE_OPTIONS['nstb-n3']},` : ''}`
                  + `${counter['nstb-n4Num'] > 0 ? ` ${counter['nstb-n4Num']} ${qcvEnums.TYPE_OPTIONS['nstb-n4']},` : ''}`
                  + `${counter['nstb-n5Num'] > 0 ? ` ${counter['nstb-n5Num']} ${qcvEnums.TYPE_OPTIONS['nstb-n5']},` : ''}`
                  + `${counter['nstb-n6Num'] > 0 ? ` ${counter['nstb-n6Num']} ${qcvEnums.TYPE_OPTIONS['nstb-n6']},` : ''}`
                  + `${counter['nstb-n7Num'] > 0 ? ` ${counter['nstb-n7Num']} ${qcvEnums.TYPE_OPTIONS['nstb-n7']},` : ''}`
                  + `${counter['nstb-g6Num'] > 0 ? ` ${counter['nstb-g6Num']} ${qcvEnums.TYPE_OPTIONS['nstb-g6']},` : ''}`
                  + `${counter['nstb-g7Num'] > 0 ? ` ${counter['nstb-g7Num']} ${qcvEnums.TYPE_OPTIONS['nstb-g7']},` : ''}`
                  + `${counter['nstb-g8Num'] > 0 ? ` ${counter['nstb-g8Num']} ${qcvEnums.TYPE_OPTIONS['nstb-g8']},` : ''}`)
                  .replace(/.$/,'.'),
                score: Math.round(score * 100) / 100,
              },
              ...list.map((e, id) => ({
                ...e,
                title: `${id + 1}. ${e.title}`,
                name: '',
                type: qcvEnums.TYPE_OPTIONS[e.type],
                isMain: e.role === 'main' ? 1 : 0,
                linkMinhChung: e?.link || e?.preview || '',
              }))
            ];
            break;
          case 'studentReward':
            score = 0;
            counter = { 'g1Num': 0, 'g2Num': 0, 'g3-1Num': 0, 'g3-2Num': 0, 'g4Num': 0, 'g5Num': 0, 'nstb-g1Num': 0, 'nstb-g2Num': 0, 'nstb-g3Num': 0 }
            list.forEach((e) => {
              counter[`${e.type}Num`]++;
              if (e.role === 'main') counter[`${e.type}MainNum`]++;
              score += e.score;
            });
            rows = [
              {
                name: '', type: '', numberAuthor: '', isMain: '', linkMinhChung: '', note: '',
                title: (`${counter['g1Num'] + counter['g2Num'] + counter['g3-1Num'] + counter['g3-2Num'] + counter['g4Num'] + counter['g5Num'] + counter['nstb-g1Num'] + counter['nstb-g2Num'] + counter['nstb-g3Num'] + counter['nstb-g7Num'] + counter['nstb-g8Num'] > 0
                    ? `${counter['g1Num'] + counter['g2Num'] + counter['g3-1Num'] + counter['g3-2Num'] + counter['g4Num'] + counter['g5Num'] + counter['nstb-g1Num'] + counter['nstb-g2Num'] + counter['nstb-g3Num'] + counter['nstb-g7Num'] + counter['nstb-g8Num']} huy chương/giải thưởng thuộc các ngành xét giải. Trong đó:`
                    : ''}`
                  + `${counter['g1Num'] > 0 ? ` ${counter['g1Num']} ${ptnsEnums.TYPE_OPTIONS['g1']},` : ''}`
                  + `${counter['g2Num'] > 0 ? ` ${counter['g2Num']} ${ptnsEnums.TYPE_OPTIONS['g2']},` : ''}`
                  + `${counter['g3-1Num'] > 0 ? ` ${counter['g3-1Num']} ${ptnsEnums.TYPE_OPTIONS['g3-1']},` : ''}`
                  + `${counter['g3-2Num'] > 0 ? ` ${counter['g3-2Num']} ${ptnsEnums.TYPE_OPTIONS['g3-2']},` : ''}`
                  + `${counter['g4Num'] > 0 ? ` ${counter['g4Num']} ${ptnsEnums.TYPE_OPTIONS['g4']},` : ''}`
                  + `${counter['g5Num'] > 0 ? ` ${counter['g5Num']} ${ptnsEnums.TYPE_OPTIONS['g5']},` : ''}`
                  + `${counter['nstb-g1Num'] > 0 ? ` ${counter['nstb-g1Num']} ${ptnsEnums.TYPE_OPTIONS['nstb-g1']},` : ''}`
                  + `${counter['nstb-g2Num'] > 0 ? ` ${counter['nstb-g2Num']} ${ptnsEnums.TYPE_OPTIONS['nstb-g2']},` : ''}`
                  + `${counter['nstb-g3Num'] > 0 ? ` ${counter['nstb-g3Num']} ${ptnsEnums.TYPE_OPTIONS['nstb-g3']},` : ''}`)
                  .replace(/.$/,'.'),
                score: Math.round(score * 100) / 100,
              },
              ...list.map((e, id) => ({
                ...e,
                title: `${id + 1}. ${e.title}`,
                name: '',
                type: ptnsEnums.TYPE_OPTIONS[e.type],
                isMain: e.role === 'main' ? 1 : 0,
                linkMinhChung: e?.link || e?.preview || '',
              }))
            ];
            break;
          default:
            break;
        }

        worksheet.addRows(rows);

        worksheet.getCell(`B${worksheet.rowCount - rows.length + 1}`).style = {
          font: { bold: true, color: { argb: 'FFFF0000' } },
        }
      }
    });

    // Các hạng mục thành tích khác
    const activityReward = JSON.parse(form?.reward || '[]');
    const socialActivity = JSON.parse(form?.socialActivity || '[]');

    if (activityReward.length > 0) {
      var rows = [
        {
          name: '', type: '', numberAuthor: '', isMain: '', score: form.scoreD, linkMinhChung: '', note: '',
          title: 'Thành tích khen thưởng'
        },
        ...activityReward.map((e) => ({
          ...e,
          note: '',
          name: '',
          title: e.rewardContent,
          type: ptnsEnums.PTNS_LEVELS.find((lvl) => lvl.value === e.typeOfReward)?.title,
          isMain: '',
          score: '',
          numberAuthor: '',
          linkMinhChung: fileRender(e.rewardFile),
        }))
      ];

      worksheet.addRows(rows);

      worksheet.getCell(`B${worksheet.rowCount - rows.length + 1}`).style = {
        font: { bold: true, color: { argb: 'FFFF0000' } },
        alignment: { wrapText: true },
      }
    }

    if (socialActivity.length > 0) {
      var rows = [
        {
          name: '', type: '', numberAuthor: '', isMain: '', score: form.scoreE, linkMinhChung: '', note: '',
          title: 'Thành tích hoạt động đoàn, hội, đội'
        },
        ...socialActivity.map((e) => ({
          ...e,
          note: '',
          name: '',
          title: e.socialActivityContent,
          type: '',
          score: '',
          isMain: '',
          numberAuthor: '',
          linkMinhChung: fileRender(e.socialActivityFile),
        }))
      ];

      worksheet.addRows(rows);

      worksheet.getCell(`B${worksheet.rowCount - rows.length + 1}`).style = {
        font: { bold: true, color: { argb: 'FFFF0000' } },
        alignment: { wrapText: true },
      }

      
    }

    worksheet.addRows([
      {
        name: '', type: '', numberAuthor: '', isMain: '', score: form.scoreB, linkMinhChung: '', note: '',
        title: `Điểm trung bình chung học tập của 3 năm học trở lên: ${form.gpa}`
      },
      {
        name: '', type: '', numberAuthor: '', isMain: '', score: form.scoreC, linkMinhChung: '', note: '',
        title: `Số lượng môn chuyên ngành đã học tập: ${form.majorCourseNumber}`
      },
      {
        name: '', type: '', numberAuthor: '', isMain: '', score: form.scoreF, linkMinhChung: '', note: '',
        title: `Ưu tiên nữ sinh là người dân tộc: ${form.profile.ethnic}`
      },
      {
        name: '', type: '', numberAuthor: '', isMain: '', score: form.scoreG, linkMinhChung: '', note: '',
        title: `Ưu tiên nữ sinh khuyết tật: ${form.bonus === 'khong' ? 'Không' : 'Có'}`
      },
    ]);

    worksheet.getCell(`B${worksheet.rowCount}`).style = {
      font: { bold: true, color: { argb: 'FFFF0000' } },
      alignment: { wrapText: true },
    }
    worksheet.getCell(`B${worksheet.rowCount - 1}`).style = {
      font: { bold: true, color: { argb: 'FFFF0000' } },
      alignment: { wrapText: true },
    }

    const previewFileList = form?.previewFile?.split(',');
    const gpaFile = previewFileList?.find((e) => e.includes('gpa.'));
    const nominatorFile = previewFileList?.find((e) => e.includes('nominator.'));
    const curriculumFile = previewFileList?.find((e) => e.includes('curriculum.'));

    worksheet.getCell(`A${startRow + 2}`).value = {
      'richText': [
        { font: { bold: true }, text: 'Ngày sinh: '}, { text: dayjs(form?.profile?.birth).format('DD/MM/YYYY') },
        { font: { bold: true }, text: '\nGiới tính: '}, { text: form?.profile?.gender === 'male' ? 'Nam' : 'Nữ' },
        { font: { bold: true }, text: '\nDân tộc: '}, { text: form?.profile?.ethnic },
        { font: { bold: true }, text: '\nĐT: '}, { text: form?.profile?.phone },
        { font: { bold: true }, text: '\nEmail: '}, { text: form?.profile?.email },
        { font: { bold: true }, text: '\nĐơn vị đề cử: '}, { text: form?.nominator },
        { font: { bold: true }, text: '\nTên trường/học viện đang theo học: '}, { text: form?.university },
        { font: { bold: true }, text: '\nTên khoa/viện: '}, { text: form?.faculty },
        { font: { bold: true }, text: '\nSinh viên năm thứ: '}, { text: form?.studentYear },
        { font: { bold: true }, text: '\nĐiểm TB chung học tập: '}, { text: form?.gpa },
        { font: { bold: true }, text: '\nSố tín chỉ chuyên ngành đã học: '}, { text: form?.majorCourseNumber },
        { font: { bold: true }, text: '\nWebsite/facebook: \n'}, { text: form?.profile?.website },
        // { font: { bold: true }, text: '\nLink file công văn đề cử: \n'}, { text: fileRender(nominatorFile) },
        // { font: { bold: true }, text: '\nLink file bảng điểm học tập: \n'}, { text: fileRender(gpaFile) }, { text: '\n'},
        // { font: { bold: true }, text: '\nLink file khung chương trình đào tạo: \n'}, { text: fileRender(curriculumFile) }, { text: '\n'},
        // { font: { bold: true }, text: '\nLink video giới thiệu bản thân: \n'}, { text: form?.videoUrl }, { text: '\n'},
      ]
    };

    worksheet.getCell(`A${startRow + 2}`).style = {
      alignment: { vertical: 'top' },
    }

    worksheet.mergeCells(`A${startRow + 2}:A${worksheet.rowCount}`);
  });

  for (var row = 6; row <= worksheet.actualRowCount; row++) {
    for (var col = 0; col < worksheet.actualColumnCount; col++) {
      const cell = worksheet.getCell(`${alphabet[col]}${row}`);
      setCellValue(cell, {
        font: { size: 10, ... cell.font },
        alignment: { wrapText: true, vertical: 'middle', ... cell.alignment },
        border: qcvEnums.FULL_BORDER
      });
    }
  }

  workbook.xlsx.writeBuffer().then(function(buffer) {
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), 'PTNS.xlsx');
  });
}
