import { HEADERS } from '.';

export const FORM_API = {
  getApplicationByProfileId: (id) => ({
    endPoint: `/api/v1/form/profileId/${id}`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  filterApplicationForm: () => ({
    endPoint: '/api/v1/form/filter',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'PUT',
  }),
  insertApplicationForm: () => ({
    endPoint: '/api/v1/form/create',
    headers: HEADERS.TOKEN_HEADER,
    method: 'POST',
  }),
  updateApplicationForm: () => ({
    endPoint: '/api/v1/form/update',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
  approveApplicationForm: (id) => ({
    endPoint: `/api/v1/form/${id}/approve`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
  thongKe: (seasonId) => ({
    endPoint: `/api/v1/form/getStat/${seasonId}`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  updateBCD: () => ({
    endPoint: '/api/v1/form/updatebcd',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
  getApplicationFormv2: (id) => ({
    endPoint: `/api/v1/form/${id}/v2`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
};

export const FORM_TYPE = {
  GET_APPLICATION_BY_PROFILE_ID_LOADING: 'GET_APPLICATION_BY_PROFILE_ID_LOADING',
  GET_APPLICATION_BY_PROFILE_ID_SUCCESS: 'GET_APPLICATION_BY_PROFILE_ID_SUCCESS',
  GET_APPLICATION_BY_PROFILE_ID_FAILURE: 'GET_APPLICATION_BY_PROFILE_ID_FAILURE',

  GET_APPLICATION_FORM_V2_LOADING: 'GET_APPLICATION_FORM_V2_LOADING',
  GET_APPLICATION_FORM_V2_SUCCESS: 'GET_APPLICATION_FORM_V2_SUCCESS',
  GET_APPLICATION_FORM_V2_FAILURE: 'GET_APPLICATION_FORM_V2_FAILURE',

  FILTER_APPLICATION_FORM_LOADING: 'FILTER_APPLICATION_FORM_LOADING',
  FILTER_APPLICATION_FORM_SUCCESS: 'FILTER_APPLICATION_FORM_SUCCESS',
  FILTER_APPLICATION_FORM_FAILURE: 'FILTER_APPLICATION_FORM_FAILURE',

  FILTER_APPLICATION_FORM_SEARCH_LOADING: 'FILTER_APPLICATION_FORM_SEARCH_LOADING',
  FILTER_APPLICATION_FORM_SEARCH_SUCCESS: 'FILTER_APPLICATION_FORM_SEARCH_SUCCESS',
  FILTER_APPLICATION_FORM_SEARCH_FAILURE: 'FILTER_APPLICATION_FORM_SEARCH_FAILURE',

  INSERT_APPLICATION_FORM_LOADING: 'INSERT_APPLICATION_FORM_LOADING',
  INSERT_APPLICATION_FORM_SUCCESS: 'INSERT_APPLICATION_FORM_SUCCESS',
  INSERT_APPLICATION_FORM_FAILURE: 'INSERT_APPLICATION_FORM_FAILURE',

  UPDATE_APPLICATION_FORM_LOADING: 'UPDATE_APPLICATION_FORM_LOADING',
  UPDATE_APPLICATION_FORM_SUCCESS: 'UPDATE_APPLICATION_FORM_SUCCESS',
  UPDATE_APPLICATION_FORM_FAILURE: 'UPDATE_APPLICATION_FORM_FAILURE',

  APPROVE_APPLICATION_FORM_LOADING: 'APPROVE_APPLICATION_FORM_LOADING',
  APPROVE_APPLICATION_FORM_SUCCESS: 'APPROVE_APPLICATION_FORM_SUCCESS',
  APPROVE_APPLICATION_FORM_FAILURE: 'APPROVE_APPLICATION_FORM_FAILURE',

  UPDATE_BCD_APPLICATION_FORM_LOADING: 'UPDATE_BCD_APPLICATION_FORM_LOADING',
  UPDATE_BCD_APPLICATION_FORM_SUCCESS: 'UPDATE_BCD_APPLICATION_FORM_SUCCESS',
  UPDATE_BCD_APPLICATION_FORM_FAILURE: 'UPDATE_BCD_APPLICATION_FORM_FAILURE',

  SET_FOCUS_FORM: 'SET_FOCUS_FORM',
  UPDATE_FOCUS_FORM: 'UPDATE_FOCUS_FORM',

  THONG_KE_TAI_KHOAN_LOADING: 'THONG_KE_TAI_KHOAN_LOADING',
  THONG_KE_TAI_KHOAN_SUCCESS: 'THONG_KE_TAI_KHOAN_SUCCESS',
  THONG_KE_TAI_KHOAN_FAILURE: 'THONG_KE_TAI_KHOAN_FAILURE',
};

export const FORM_CONSTANTS = {
  GENDER_OPTIONS: [
    { key: 'gender-male', title: 'Nam', value: 'male' },
    { key: 'gender-female', title: 'Nữ', value: 'female' },
  ],
  FIELD_QCV_OPTIONS: [
    { key: 'cntt', title: 'Công nghệ thông tin, chuyển đổi số và tự động hóa', value: 'cntt' },
    { key: 'cnyd', title: 'Công nghệ y dược', value: 'cnyd' },
    { key: 'cnsh', title: 'Công nghệ sinh học', value: 'cnsh' },
    { key: 'cnmt', title: 'Công nghệ môi trường', value: 'cnmt' },
    { key: 'cnvlm', title: 'Công nghệ vật liệu mới', value: 'cnvlm' },
  ],
  FIELD_XHNV_OPTIONS: [
    { key: 'luat', title: 'Ngành Luật học', value: 'luat' },
    { key: 'giao_duc', title: 'Ngành Giáo dục học', value: 'giao_duc' },
    { key: 'kinh_te', title: 'Ngành Kinh tế học', value: 'kinh_te' },
    { key: 'van_hoa', title: 'Liên ngành Văn hóa - Nghệ thuật', value: 'van_hoa' },
    { key: 'triet_hoc', title: 'Liên ngành Triết học - Chính trị học - Xã hội học', value: 'triet_hoc' },
    { key: 'su_hoc', title: 'Liên ngành Sử học - Khảo cổ học - Dân tộc học - Nhân học', value: 'su_hoc' },
  ],
  FIELD_PTNS_OPTIONS: [
    { key: 'cntt', title: 'Công nghệ thông tin', value: 'cntt' },
    { key: 'mt', title: 'Máy tính', value: 'mt' },
    { key: 'cnktck', title: 'Công nghệ kỹ thuật, cơ khí', value: 'cnktck' },
    { key: 'cnktddvtt', title: 'Công nghệ, kỹ thuật điện, điện tử và viễn thông', value: 'cnktddvtt' },
    { key: 'cnkhvlmmtr', title: 'Công nghệ hóa học, vật liệu, luyện kim và môi trường', value: 'cnkhvlmmtr' },
    { key: 'sinhhoc', title: 'Sinh học ứng dụng', value: 'sinhhoc' },
    { key: 'cnktktxd', title: 'Công nghệ kỹ thuật kiến trúc và công trình xây dựng', value: 'cnktktxd' },
    { key: 'cnlienquan', title: 'Nhóm ngành khác liên quan tới lĩnh vực: Công nghệ biển; Công nghệ phòng tránh thiên tai, ứng phó với biến đổi khí hậu; Công nghệ năng lượng; Công nghệ vũ trụ', value: 'cnlienquan' },
  ],
  // FIELD_PTNS_OPTIONS: [
  //   { key: 'cntt', title: 'Công nghệ thông tin', value: 'cntt' },
  //   { key: 'httt', title: 'Hệ thống thông tin', value: 'httt' },
  //   { key: 'attt', title: 'An toàn thông tin', value: 'attt' },
  //   { key: 'khmt', title: 'Khoa học máy tính', value: 'khmt' },
  //   { key: 'mhmt', title: 'Mạng máy tính và truyền thông dữ liệu', value: 'mhmt' },
  //   { key: 'ktpm', title: 'Kỹ thuật phần mềm', value: 'ktpm' },
  //   { key: 'ktmt', title: 'Kỹ thuật máy tính', value: 'ktmt' },
  //   { key: 'cnktmt', title: 'Công nghệ kỹ thuật máy tính', value: 'cnktmt' },
  //   { key: 'cnktmtdt', title: 'Công nghệ kỹ thuật điện và điện tử', value: 'cnktmtdt' },
  //   { key: 'dktdh', title: 'Công nghệ kỹ thuật điều khiển và tự động hóa', value: 'dktdh' },
  //   { key: 'cnkthh', title: 'Công nghệ kỹ thuật hóa học', value: 'cnkthh' },
  //   { key: 'cnsh', title: 'Công nghệ sinh học', value: 'cnsh' },
  //   { key: 'cnktmtr', title: 'Công nghệ kỹ thuật môi trường', value: 'cnktmtr' },
  //   { key: 'cnvl', title: 'Công nghệ vật liệu', value: 'cnvl' },
  //   { key: 'cnhn', title: 'Công nghệ kỹ thuật hạt nhân', value: 'cnhn' },
  // ],
  
  levelOptions: [
    { key: 'doctor', title: 'Tiến sĩ', value: 'doctor' },
    { key: 'master', title: 'Thạc sĩ', value: 'master' },
    { key: 'bachelor', title: 'Cử nhân', value: 'bachelor' },
    { key: 'domestic', title: 'Cấp Nhà nước và tương đương', value: 'domestic' },
    { key: 'ministry', title: 'Cấp Bộ và tương đương', value: 'ministry' },
    { key: 'school', title: 'Cơ sở', value: 'school' },
    { key: 'vn', title: 'Đề tài quốc tế giải quyết các vấn đề của Việt Nam', value: 'vn' },
  ],
  ACADEMIC_LEVEL_OPTIONS: [
    { key: 'thpt', title: 'THPT', value: 'thpt' },
    { key: 'bachelor', title: 'Đại học', value: 'bachelor' },
    { key: 'master', title: 'Thạc sĩ', value: 'master' },
    { key: 'doctor', title: 'Tiến sĩ', value: 'doctor' },
  ],

  POLITICAL_UNION_OPTIONS: [
    { key: 'doan-vien', title: 'Đoàn viên', value: 'Đoàn viên' },
    { key: 'dang-vien', title: 'Đảng viên', value: 'Đảng viên' },
  ],

  ROLE_OPTIONS: [
    { key: 'main', title: 'Tác giả chính', value: 'main' },
    { key: 'member', title: 'Đồng tác giả', value: 'member' },
    { key: 'founder', title: 'Sáng lập/Chủ doanh nghiệp', value: 'founder' },
    { key: 'manager', title: 'Quản lý', value: 'manager' },
  ],

  ALL_TYPE_OPTIONS: [
    { key: 'q1', title: 'Q1', value: 'q1' },
    { key: 'q2', title: 'Q2', value: 'q2' },
    { key: 'q3', title: 'Q3', value: 'q3' },
    { key: 'q4', title: 'Q4', value: 'q4' },
    { key: 'q5', title: 'Q5', value: 'q5' },
    { key: 'q6', title: 'Q6', value: 'q6' },
    { key: 'scopus', title: 'Scopus', value: 'scopus' },
    { key: 'b1', title: 'Báo cáo xuất sắc', value: 'b1' },
    { key: 'b2-1', title: 'Poster xuất sắc', value: 'b2-1' },
    { key: 'b2-2', title: 'Báo cáo trên hội thảo thường, không được giải', value: 'b2-2' },
    { key: 'b3', title: 'Hội thảo quốc gia', value: 'b3' },
    { key: 'sc', title: 'Sáng chế', value: 'sc' },
    { key: 'gp', title: 'Giải pháp', value: 'gp' },
    { key: 's1', title: 'Sách chuyên khảo', value: 's1' },
    { key: 's2', title: 'Sách giáo trình', value: 's2' },
    { key: 's3', title: 'Sách tham khảo', value: 's3' },
    { key: 's4', title: 'Chương sách', value: 's4' },
    { key: 'd1', title: 'Chủ nhiệm', value: 'd1' },
    { key: 'd2', title: 'Phó Chủ nhiệm', value: 'd2' },
    { key: 'd3', title: 'Thư ký', value: 'd3' },
    { key: 'g1', title: 'HCV/Giải Nhất Quốc tế', value: 'g1' },
    { key: 'g2', title: 'HCB/Giải Nhì Quốc tế', value: 'g2' },
    { key: 'g3-1', title: 'HCĐ/Giải ba Quốc tế', value: 'g3-1' },
    { key: 'g3-2', title: 'HCV/Giải nhất Quốc gia', value: 'g3-2' },
    { key: 'g4', title: 'HCB/Giải Nhì Quốc gia', value: 'g4' },
    { key: 'g5', title: 'HCĐ/Giải Ba Quốc gia', value: 'g5' },
  ],

  productionOptions: [
    { key: 'international', title: 'Quốc tế', value: 'international' },
    { key: 'domestic', title: 'Quốc gia', value: 'domestic' },
    { key: 'local', title: 'Địa phương/Đơn vị', value: 'local' },
  ],
  fieldNSTBOptions: [
    { key: 'cntt', title: 'Công nghệ thông tin', value: 'cntt' },
    { key: 'httt', title: 'Hệ thống thông tin', value: 'httt' },
    { key: 'attt', title: 'An toàn thông tin', value: 'attt' },
    { key: 'khmt', title: 'Khoa học máy tính', value: 'khmt' },
    { key: 'mhmt', title: 'Mạng máy tính và truyền thông dữ liệu', value: 'mhmt' },
    { key: 'ktpm', title: 'Kỹ thuật phần mềm', value: 'ktpm' },
    { key: 'ktmt', title: 'Kỹ thuật máy tính', value: 'ktmt' },
    { key: 'cnktmt', title: 'Công nghệ kỹ thuật máy tính', value: 'cnktmt' },
    { key: 'cnktmtdt', title: 'Công nghệ kỹ thuật điện và điện tử', value: 'cnktmtdt' },
    { key: 'dktdh', title: 'Công nghệ kỹ thuật điều khiển và tự động hóa', value: 'dktdh' },
    { key: 'cnkthh', title: 'Công nghệ kỹ thuật hóa học', value: 'cnkthh' },
    { key: 'cnsh', title: 'Công nghệ sinh học', value: 'cnsh' },
    { key: 'cnktmtr', title: 'Công nghệ kỹ thuật môi trường', value: 'cnktmtr' },
    { key: 'cnvl', title: 'Công nghệ vật liệu', value: 'cnvl' },
    { key: 'cnhn', title: 'Công nghệ kỹ thuật hạt nhân', value: 'cnhn' },
  ],
  LEVEL_INPUT_OPTIONS: [
    { key: 'national', title: 'Quốc gia', value: 'national' },
    { key: 'partyLocalHierarchy', title: 'Cấp bộ', value: 'partyLocalHierarchy' },
    { key: 'province', title: 'Tỉnh, thành phố', value: 'province' },
    { key: 'stateAgencies', title: 'Cơ quan, đơn vị', value: 'stateAgencies' },
  ],
  HR_DEVELOPMENT_TYPE: [
    { key: "boiDuong", title: "Bồi dưỡng", value: "boiDuong" },
    { key: "daoTao", title: "Đào tạo", value: "daoTao" },
  ]
};
