import dayjs from 'dayjs';
import React from 'react';

import { ARCHIVEMENT_TYPE, KEYS } from "../constants/enums";
import { Typography } from '@material-ui/core';
import { FORM_CONSTANTS, RESEARCH_CONSTANTS, TECH_COMPANY_CONSTANTS } from '../constants';

export const columnRender = (e, setDialog, setPreviewFile) => {
  const columns = [
    { title: <b>Nội dung</b>, dataIndex: "title", width: "30%" },
    {
      title: <b>Phân loại</b>,
      dataIndex: "type",
      width: "20%",
      render: (value) => FORM_CONSTANTS.ALL_TYPE_OPTIONS.find((e) => e.value === value)?.title,
    },
    { title: <b>Số tác giả</b>, dataIndex: "numberAuthor", width: "15%" },
    {
      title: <b>Vai trò</b>,
      dataIndex: "role",
      width: "20%",
      render: (value) => {
        if ( value === "main" && (e.type === "research-qt" || e.type === "research-qtk") ) {
          return "Tác giả chính (hoặc tác giả liên hệ)";
        } else {
          return RESEARCH_CONSTANTS.ROLE_OPTIONS.find((e) => e.value === value)?.title;
        }
      },
    },
    {
      title: <b>Tác vụ</b>,
      dataIndex: "link",
      width: "15%",
      render: (value, row) => (
        <>
          {row?.link.includes("/storage/awardRegister/profile") ? (
            <Typography
              variant="body2"
              className="actions-button"
              onClick={() => setPreviewFile(row?.link)}
            >
              Minh chứng
            </Typography>
          ) : (
            <a target="_blank" rel="noreferrer" href={value}>
              Link DOI
            </a>
          )}
          {row?.preview?.includes("/storage/awardRegister/profile") && (
            <Typography
              variant="body2"
              className="actions-button"
              onClick={() => setPreviewFile(row?.preview)}
            >
              Minh chứng
            </Typography>
          )}
        </>
      ),
    },
  ];

  if (e.type === ARCHIVEMENT_TYPE.TECH_COMPANY) {
    return [
      { title: <b>Sản phẩm ứng dụng thực tế</b>, dataIndex: "production", width: "35%" },
      {
        title: <b>Giá trị của sản phẩm</b>,
        dataIndex: "value",
        width: "25%",
        render: (value) =>
          `${value
            ?.toString()
            .replace(/\D/g, "")
            ?.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ".")} VNĐ`,
      },
      {
        title: <b>Phạm vi ứng dụng</b>,
        dataIndex: "applicationRange",
        width: "25%",
        render: (value) => (TECH_COMPANY_CONSTANTS.productionOptions.find((e) => e.value === value)?.title),
      },
      { ...columns[4] },
    ];
  } else if (e.type === ARCHIVEMENT_TYPE.SOLUTION) {
    return [
      { title: <b>Tên sản phẩm</b>, dataIndex: "title", width: "35%" },
      { title: <b>Tác Giả</b>, dataIndex: "author", width: "25%" },
      {
        title: <b>Ngày cấp</b>,
        dataIndex: "issueAt",
        width: "25%",
        render: (value) => dayjs(value).format("DD/MM/YYYY"),
      },
      { ...columns[4] },
    ];
  } else if (e.type === ARCHIVEMENT_TYPE.RESEARCH_PROJECT) {
    return [
      { title: <b>Tên sản phẩm</b>, dataIndex: "title", width: "35%" },
      {
        title: <b>Cấp dự án</b>,
        dataIndex: "level",
        width: "25%",
        render: (value) => FORM_CONSTANTS.LEVEL_INPUT_OPTIONS.find((e) => e.value === value)?.title,
      },
      { ...columns[4] },
    ];
  } else if (e.type === ARCHIVEMENT_TYPE.REWARD) {
    return [
      { ...columns[0], width: "30%" },
      { ...columns[1], width: "55%" },
      { ...columns[4] },
    ];
  } else if (e.type === ARCHIVEMENT_TYPE.HR_DEVELOPMENT + KEYS.DAO_TAO) {
    return [
      { title: <b>Tên học viên</b>, dataIndex: "traineeName", width: "35%" },
      { title: <b>Cơ sở đào tạo</b>, dataIndex: "unit", width: "35%" },
      {
        title: <b>Chương trình đào tạo</b>,
        dataIndex: "academicLevel",
        width: "30%",
        render: (value) => FORM_CONSTANTS.ACADEMIC_LEVEL_OPTIONS.find((e) => e.value === value)?.title,
      },
      { ...columns[4] }
    ];
  } else if (e.type === ARCHIVEMENT_TYPE.HR_DEVELOPMENT + KEYS.BOI_DUONG) {
    return [
      { title: <b>Tên chương trình</b>, dataIndex: "programName", width: "35%" },
      { title: <b>Đơn vị tổ chức</b>, dataIndex: "unit", width: "35%" },
      { title: <b>Số lượng nhân lực được bồi dưỡng</b>, dataIndex: "traineeNum", width: "30%" },
      { ...columns[4] }
    ];
  }
  return columns
}