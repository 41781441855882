import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Menu,
  Note,
  Close,
  Person,
  VpnKey,
  ExitToApp,
  ExpandMore,
} from '@material-ui/icons';
import {
  Box,
  Link,
  Button,
  AppBar,
  Drawer,
  Hidden,
  MenuItem,
  Accordion,
  Container,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import { useScene } from '../../../hooks/useScene';
import { userActions } from '../../../actions';
import { ROUTER, ROUTER_CONSTANTS } from '../../../constants';

import styles from './AppHeader.module.css';
import PopupMenu from '../../../components/CustomComponents/PopupMenu';
import tnvLogoDark from '../../../assets/image/app/tnv-logo-dark.png';


function AppHeader() {
  const scene = useScene();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const routeListQCV = [...ROUTER_CONSTANTS.routeListQCV];
  const routeListPTNS = [...ROUTER_CONSTANTS.routeListPTNS];
  const routerListXHNV = [...ROUTER_CONSTANTS.routerListXHNV];

  const [openDrawer, setOpenDrawer] = useState(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState(false);
  const [adminMenuAnchor, setAdminMenuAnchor] = useState(false);

  const feature = window.location.href;
  const user = useSelector(state => state.userReducer.user);

  const openUserMenu = (e) => setUserMenuAnchor(e.currentTarget);
  const closeUserMenu = () => setUserMenuAnchor(null);

  const openAdminMenu = (e) => setAdminMenuAnchor(e.currentTarget);
  const closeAdminMenu = () => setAdminMenuAnchor(null);

  const routeTo = (route) => {
    navigate(route);
    closeUserMenu();
    closeAdminMenu();
  }

  const logout = () => {
    dispatch(userActions.logout(navigate));
    closeUserMenu();
    closeAdminMenu();
  }

  if (user?.role === 'council' || user?.role === 'super_admin') {
    routeListQCV.push({ key: 'binh-chon', title: 'Bình chọn', to: ROUTER.QCV.BINH_CHON })
  }

  if (user?.role === 'council_qcv') {
    routeListQCV.push({ key: 'binh-chon', title: 'Bình chọn', to: ROUTER.QCV.BINH_CHON })
    // routeListPTNS.push({ key: 'binh-chon', title: 'Bình chọn', to: ROUTER.QCV.BINH_CHON })
  }


  if (user?.role.includes('qcv_admin')) {
    routeListQCV.push({ key: 'danh-sach-ho-so', title: 'Duyệt hồ sơ', to: ROUTER.DANH_SACH_HO_SO });
  }


  if (user?.role === 'super_admin') {
    routeListPTNS.splice(2, 0,
      // { key: 'danh-sach-ho-so', title: 'Danh sách hồ sơ', to: ROUTER.PTNS.DANH_SACH_HO_SO },
    );
  }

  

  const userMenu = [
    { key: 'thong-tin', title: 'Thông tin cá nhân', icon: <Person />, onClick: () => routeTo(ROUTER.THONG_TIN_CA_NHAN) },
    { key: 'ds-hs', title: 'Danh sách hồ sơ', icon: <Note />, onClick: () => routeTo(ROUTER.DANH_SACH_HO_SO_CA_NHAN) },
    { key: 'doi-mk', title: 'Đổi mật khẩu', icon: <VpnKey />, onClick: () => routeTo(ROUTER.DOI_MAT_KHAU) },
    { key: 'dang-xuat', title: 'Đăng xuất', icon: <ExitToApp />, onClick: logout },
  ];

  const qcvAdminMenu = [
    { key: 'nam', title: 'Quản lý năm tổ chức', onClick: () => routeTo(ROUTER.QUAN_LY_NAM_TO_CHUC) },
    { key: 'danh-sach-ho-so', title: 'Danh sách hồ sơ', onClick: () => routeTo(ROUTER.DANH_SACH_HO_SO) },
    { key: 'duyet-thanh-tich-ca-nhan', title: 'Danh sách thành tích', onClick: () => routeTo(ROUTER.QCV.DUYET_THANH_TICH_CA_NHAN.BANG_SANG_CHE) },
    { key: 'quan-ly-nguoi-dung', title: 'Quản lý người dùng', onClick: () => routeTo(ROUTER.QUAN_LY_NGUOI_DUNG) },
    { key: 'cau-hinh', title: 'Cấu hình Bảng tính điểm', onClick: () => routeTo(ROUTER.CAU_HINH) },
  ];

  const routeList = scene.isQuaCauVang ? routeListQCV : scene.isXaHoiNhanVan ? routerListXHNV : routeListPTNS;

  return (
    <AppBar className={styles.appBar} position='fixed'>
      <Hidden smDown>
        <Container maxWidth="lg">
          <Box display="flex" height={64} alignItems='center' justifyContent='space-between'>
            <Box display='flex' alignItems='center'>
              <img alt='logo' src={tnvLogoDark} style={{ marginRight: 32, height: 52, marginTop: 4 }} />
              {routeList.map((e) => (
                <Link className={styles.routeButton} key={e.key} href={e.to}>{e.title}</Link>
              ))}
              {(user?.role === 'super_admin') && (
                <Link className={`${styles.routeButton} ${styles.adminButton}`} onClick={openAdminMenu}>
                  Quản trị viên
                  <svg className={styles.downIcon} viewBox='7 10 10 5'><path d='M7 10l5 5 5-5z' /></svg>
                </Link>
              )}
            </Box>
            <Box display='flex' alignItems='center' justifyContent='flex-end'>
              {user ? (
                <Box className={`${styles.routeButton} ${styles.userButton}`} onClick={openUserMenu}>
                  {user.username}
                  <svg className={styles.downIcon} viewBox='7 10 10 5'><path d='M7 10l5 5 5-5z' /></svg>
                </Box>
                ) : (
                  <>
                    <Link href={ROUTER.DANG_KY} className={styles.routeButton}>Đăng ký</Link>
                    <Button
                      color='secondary'
                      variant='contained'
                      href={ROUTER.DANG_NHAP}
                      style={{ float: 'right', margin: '10px 0 10px 16px' }}
                    >
                      Đăng nhập
                    </Button>
                  </>
                )}
            </Box>
          </Box>
        </Container>
      </Hidden>
      <Hidden mdUp>
        <Container maxWidth="xl">
          <Box display="flex" height={64} alignItems='center' justifyContent='space-between'>
            <Box display='flex' alignItems='center'>
              <img alt='logo' src={tnvLogoDark} style={{ marginRight: 32, height: 52, marginTop: 4 }} />
            </Box>
            <Box width='100%'>
              {openDrawer ? (
                <Close style={{ color: 'black', float: 'right' }} onClick={() => setOpenDrawer(false)} />
              ) : (
                <Menu style={{ color: 'black', float: 'right' }} onClick={() => setOpenDrawer(true)} />
              )}
            </Box>
          </Box>
        </Container>
      </Hidden>
      <PopupMenu
        position='right'
        anchorEl={userMenuAnchor}
        handleClose={closeUserMenu}
        render={
          <>
            {userMenu.map((e) => (
              <MenuItem key={e.key} onClick={e.onClick}>
                {e.icon}
                <Box className={styles.userMenu}>{e.title}</Box>
              </MenuItem>
            ))}
          </>
        } 
      />

      <PopupMenu
        position='center'
        anchorEl={adminMenuAnchor}
        disableRestoreFocus
        handleClose={closeAdminMenu}
        render={
          <>
            {qcvAdminMenu.map((e) => (
              <MenuItem key={e.key} onClick={e.onClick} style={{ fontWeight: 'bold' }}>
                {e.title}
              </MenuItem>
            ))}
          </>
        } 
      />

      <Drawer
        anchor='top'
        open={openDrawer}
        PaperProps={{ style: { marginTop: 64, color: 'black' } }}
        onClose={() => setOpenDrawer(false)}
        style={{ zIndex: 2 }}
      >
        {routeList.map((e) => (
          <Link
            key={e.key}
            href={e.to}
            className={styles.mobileRouteButton}
            style={{
              borderLeftColor: feature.includes(e.to) ? 'black' : '#ffffff00',
              backgroundColor: feature.includes(e.to) ? '#00000040' : '#ffffff00',
            }}
          >
            {e.title}
          </Link>
        ))}
        {(user?.role === 'super_admin') && (
          <Accordion className={styles.mobileUserCollapse} TransitionProps={{ style: { marginTop: 0} }}>
            <AccordionSummary className={styles.mobileUser} expandIcon={<ExpandMore />}>
              <Typography><b>Quản trị viên</b></Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.mobileUserMenu}>
              <Box width='100%'>
                {qcvAdminMenu.map((e) => (
                  <MenuItem key={e.key} onClick={() => { e.onClick(); setOpenDrawer(false); }} style={{ height: 56 }}>
                    <Typography variant='subtitle1' style={{ marginLeft: 16, fontWeight: 600 }}>{e.title}</Typography>
                  </MenuItem>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        {user ? (
          <Accordion className={styles.mobileUserCollapse} TransitionProps={{ style: { marginTop: 0} }}>
            <AccordionSummary className={styles.mobileUser} expandIcon={<ExpandMore />}>
              <Typography style={{ fontWeight: 600 }}>{user.username}</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.mobileUserMenu}>
              <Box width='100%'>
                {userMenu.map((e) => (
                  <MenuItem key={e.key} onClick={() => { e.onClick(); setOpenDrawer(false); }} style={{ height: 56 }}>
                    {e.icon}
                    <Typography variant='subtitle1' style={{ marginLeft: 16, fontWeight: 600 }}>{e.title}</Typography>
                  </MenuItem>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : (
          <Box style={{ marginLeft: 20, lineHeight: '56px' }} onClick={() => { setOpenDrawer(false); navigate(ROUTER.DANG_NHAP); }}>
            <b>Đăng nhập</b>
          </Box>
        )}
      </Drawer>
    </AppBar>
  )
}

export default AppHeader;
