import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { Grid, Select, MenuItem, Typography, Container, Box } from '@material-ui/core';

import { KEYS } from '../../../constants/enums';
import { ROUTER } from '../../../constants';

import DuAn from '../../QCV/DuAnNghienCuu';
import BaiBao from '../../QCV/BaiBao';
import BaoCao from '../../QCV/BaoCao';
// import DaoTao from '../../QCV/DaoTao';
import GiaiThuong from '../../QCV/GiaiThuong';
import DoanhNghiep from '../../QCV/DoanhNghiep';
import BangSangChe from '../../QCV/BangSangChe';
import SachChuyenKhao from '../../QCV/SachChuyenKhao';
import AppHeader from '../../../containers/AppLayout/AppHeader';
import AppFooter from '../../../containers/AppLayout/AppFooter/AppFooter';


export const ROUTER_CONFIG_DUYET_THANH_TICH_CA_NHAN = [
  { path: ROUTER.QCV.DUYET_THANH_TICH_CA_NHAN.BANG_SANG_CHE, title: 'Bằng độc quyền sáng chế, giải pháp hữu ích', element: <BangSangChe /> },
  { path: ROUTER.QCV.DUYET_THANH_TICH_CA_NHAN.BAI_BAO_QUOC_TE, title: 'Bài báo khoa học đăng trên tạp chí quốc tế', element: <BaiBao form={KEYS.INTERNATIONAL} /> },
  { path: ROUTER.QCV.DUYET_THANH_TICH_CA_NHAN.BAI_BAO_TRONG_NUOC, title: 'Bài báo khoa học đăng trên tạp chí trong nước', element: <BaiBao form={KEYS.DOMESTIC} /> },
  { path: ROUTER.QCV.DUYET_THANH_TICH_CA_NHAN.BAO_CAO, title: 'Bài báo khoa học đăng trên hội thảo', element: <BaoCao /> },
  { path: ROUTER.QCV.DUYET_THANH_TICH_CA_NHAN.BANG_SACH, title: 'Chương trình, dự án, đề tài nghiên cứu khoa học', element: <DuAn /> },
  { path: ROUTER.QCV.DUYET_THANH_TICH_CA_NHAN.DU_AN_NGHIEN_CUU, title: 'Sách chuyên khảo, chương sách', element: <SachChuyenKhao /> },
  // { path: ROUTER.QCV.DUYET_THANH_TICH_CA_NHAN.PHAT_TRIEN_NGUON_NHAN_LUC, title: 'Phát triển nguồn nhân lực', element: <DaoTao /> },
  { path: ROUTER.QCV.DUYET_THANH_TICH_CA_NHAN.DOANH_NGHIEP_CONG_NGHE, title: 'Sản phẩm khoa học công nghệ', element: <DoanhNghiep /> },
  { path: ROUTER.QCV.DUYET_THANH_TICH_CA_NHAN.GIAI_THUONG, title: 'Giải thưởng, huy chương đã đạt được', element: <GiaiThuong /> },
];


function DuyetThanhTichCaNhan() {
  const url = window.location.href;
  const navigate = useNavigate();
  const [value, setValue] = useState(ROUTER_CONFIG_DUYET_THANH_TICH_CA_NHAN.find((e) => url.includes(e.path))?.path);
  const { profile, userId } = useSelector((state) => ({
    userId: state.userReducer.user?.id,
    profile: state.profileReducer.profile,
  }));

  document.body.style.backgroundColor = '#FFF';

  const outletComponent = () => {
    if (isNaN(userId) && !localStorage.getItem('refresh_token')) {
      return (
        <Box textAlign='center' marginTop={4}>
          <NavLink to={ROUTER.DANG_NHAP}>Đăng nhập để truy cập tính năng này</NavLink>
        </Box>
      );
    } else if (!isNaN(userId) && !profile) {
      return (
        <Box textAlign='center' marginTop={4}>
          <NavLink to={ROUTER.THONG_TIN_CA_NHAN}>Hoàn thiện thông tin cá nhân để truy cập tính năng này</NavLink>
        </Box>
      )
    } else {
      return (
        <Grid container spacing={2} justifyContent='flex-end' style={{ width: '100%', margin: 0 }}>
          <Grid item xs={12} md={3}>
            <Typography variant='h5'><b>Duyệt thành tích</b></Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              fullWidth
              size='small'
              value={value || ''}
              variant='outlined'
              SelectDisplayProps={{ style: { padding: 8 } }}
              MenuProps={{
                style: { left: window.innerWidth <= 768 ? -8 : 0 },
                getContentAnchorEl: null,
                MenuListProps: { style: { padding: 0 } },
                PaperProps: { style: { left: 8 } },
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
              }}
              onChange={(e) => {
                setValue(e.target.value);
                navigate(e.target.value);
              }}
            >
              {ROUTER_CONFIG_DUYET_THANH_TICH_CA_NHAN.map((opt, id) => <MenuItem key={opt.path} value={opt.path}>
                {`${id + 1}. ${opt.title}`}
              </MenuItem>)}
            </Select>
          </Grid>
          <Outlet />
        </Grid>
      )
    }
  }

  return (
    <>
      <AppHeader />
      <Container maxWidth='lg' style={{ paddingTop: 96, paddingBottom: 32, minHeight: '100vh' }}>
        {outletComponent()}
      </Container>
      <AppFooter />
    </>
  );
}

export default DuyetThanhTichCaNhan;
