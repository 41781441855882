export const ROUTER = {
  HOME: '/',
  HO_SO: '/ho-so',
  DANG_KY: '/dang-ky',
  CAU_HINH: '/cau-hinh',
  DANG_NHAP: '/dang-nhap',
  TONG_QUAN: '/tong-quan',
  GIOI_THIEU: '/gioi-thieu',
  DOI_MAT_KHAU: '/doi-mat-khau',
  DANG_KY_HO_SO: '/dang-ky-ho-so',
  QUAN_LY_CHUNG: '/quan-ly-chung',
  DANH_SACH_HO_SO: '/danh-sach-ho-so',
  THONG_TIN_CA_NHAN: '/thong-tin-ca-nhan',
  QUAN_LY_NGUOI_DUNG: '/quan-ly-nguoi-dung',
  QUAN_LY_NAM_TO_CHUC: '/quan-ly-nam-to-chuc',
  DANH_SACH_HO_SO_CA_NHAN: '/danh-sach-ho-so-ca-nhan',
  QCV: {
    HOME: '/',
    GUONG_MAT: '/guong-mat',
    BINH_CHON: '/binh-chon',
    BINH_CHON_V2: '/binh-chon-v2',
    KET_QUA_BINH_CHON: '/ket-qua-binh-chon',
    DANH_SACH_THANH_TICH: '/danh-sach-thanh-tich',
    DUYET_THANH_TICH: '/duyet-thanh-tich-ca-nhan',
    HUONG_DAN_SU_DUNG: '/huong-dan-su-dung',
    DUYET_THANH_TICH_CA_NHAN: {
      HOME: '/duyet-thanh-tich-ca-nhan',
      BAI_BAO: '/duyet-thanh-tich-ca-nhan/bai-bao',
      BAI_BAO_QUOC_TE: '/duyet-thanh-tich-ca-nhan/bai-bao-quoc-te',
      BAI_BAO_TRONG_NUOC: '/duyet-thanh-tich-ca-nhan/bai-bao-trong-nuoc',
      BAO_CAO: '/duyet-thanh-tich-ca-nhan/bao-cao',
      BANG_SACH: '/duyet-thanh-tich-ca-nhan/bang-sach',
      BANG_SANG_CHE: '/duyet-thanh-tich-ca-nhan/bang-sang-che',
      DU_AN_NGHIEN_CUU: '/duyet-thanh-tich-ca-nhan/du-an-nghien-cuu',
      GIAI_THUONG: '/duyet-thanh-tich-ca-nhan/giai-thuong-dat-duoc',
      DOANH_NGHIEP_CONG_NGHE: '/duyet-thanh-tich-ca-nhan/doanh-nghiep-cong-nghe',
      PHAT_TRIEN_NGUON_NHAN_LUC: '/duyet-thanh-tich-ca-nhan/phat-trien-nguon-nhan-luc',
    },
    THANH_TICH_CA_NHAN: {
      HOME: '/thanh-tich-ca-nhan',
      BAI_BAO: '/thanh-tich-ca-nhan/bai-bao',
      BAI_BAO_QUOC_TE: '/thanh-tich-ca-nhan/bai-bao-quoc-te',
      BAI_BAO_QUOC_TE_KHAC: '/thanh-tich-ca-nhan/bai-bao-khac',
      BAI_BAO_TRONG_NUOC: '/thanh-tich-ca-nhan/bai-bao-trong-nuoc',
      BAO_CAO: '/thanh-tich-ca-nhan/bao-cao',
      BANG_SACH: '/thanh-tich-ca-nhan/bang-sach',
      BANG_SANG_CHE: '/thanh-tich-ca-nhan/bang-sang-che',
      DU_AN_NGHIEN_CUU: '/thanh-tich-ca-nhan/du-an-nghien-cuu',
      GIAI_THUONG: '/thanh-tich-ca-nhan/giai-thuong-dat-duoc',
      PHAT_TRIEN_NGUON_NHAN_LUC: '/thanh-tich-ca-nhan/phat-trien-nguon-nhan-luc',
      DOANH_NGHIEP_CONG_NGHE: '/thanh-tich-ca-nhan/doanh-nghiep-cong-nghe',
      DE_TAI_QUOC_TE: '/thanh-tich-ca-nhan/de-tai-quoc-te',
    }
  },
  PTNS: {
    HOME: '/',
    DANH_SACH_HO_SO: '/danh-sach-ho-so',
    HO_SO: '/ho-so/',
    DANG_KY_HO_SO: '/dang-ky-ho-so',
    GIOI_THIEU: '/gioi-thieu',
  }
};

export const ROUTER_CONSTANTS = {
//   options : [
//     { key: 'bang-sang-che', title: 'Bằng độc quyền sáng chế, giải pháp hữu ích', value: ROUTER.QCV.THANH_TICH_CA_NHAN.BANG_SANG_CHE},
//     { key: 'bai-bao-quoc-te', title: 'Bài báo khoa học đăng trên tạp chí quốc tế', value: ROUTER.QCV.THANH_TICH_CA_NHAN.BAI_BAO_QUOC_TE },
//     // { key: 'bai-bao-khac', title: 'Bài báo khoa học đăng trên tạp chí quốc tế khác', value: ROUTER.QCV.THANH_TICH_CA_NHAN.BAI_BAO_QUOC_TE_KHAC },
//     { key: 'bai-bao-trong-nuoc', title: 'Bài báo khoa học đăng trên tạp chí trong nước', value: ROUTER.QCV.THANH_TICH_CA_NHAN.BAI_BAO_TRONG_NUOC },
//     { key: 'bao-cao', title: 'Bài báo khoa học đăng trên hội thảo', value: ROUTER.QCV.THANH_TICH_CA_NHAN.BAO_CAO },
//     { key: 'du-an-nghien-cuu', title: 'Chương trình, dự án, đề tài nghiên cứu khoa học', value: ROUTER.QCV.THANH_TICH_CA_NHAN.DU_AN_NGHIEN_CUU},
//     { key: 'giai-thuong-dat-duoc', title: 'Giải thưởng, huy chương đã đạt được', value: ROUTER.QCV.THANH_TICH_CA_NHAN.GIAI_THUONG},
//     { key: 'bang-sach', title : 'Sách chuyên khảo, chương sách', value: ROUTER.QCV.THANH_TICH_CA_NHAN.BANG_SACH},
//     { key: 'nhan-luc', title : 'Phát triển nguồn nhân lực', value: ROUTER.QCV.THANH_TICH_CA_NHAN.PHAT_TRIEN_NGUON_NHAN_LUC},
//     { key: 'doanh-nghiep', title: 'Sản phẩm khoa học công nghệ', value: ROUTER.QCV.THANH_TICH_CA_NHAN.DOANH_NGHIEP_CONG_NGHE},
//   ],
  routeListQCV : [
    { key: 'gioi-thieu', title: 'Giới thiệu', to: ROUTER.GIOI_THIEU },
    { key: 'thanh-tich-ca-nhan', title: 'Thành tích cá nhân', to: ROUTER.QCV.THANH_TICH_CA_NHAN.BANG_SANG_CHE },
    { key: 'dang-ky-ho-so', title: 'Đăng ký hồ sơ', to: ROUTER.DANG_KY_HO_SO },
  ],
  routeListPTNS : [
    { key: 'gioi-thieu', title: 'Giới thiệu', to: ROUTER.GIOI_THIEU },
    { key: 'dang-ky-ho-so', title: 'Đăng ký hồ sơ', to: ROUTER.DANG_KY_HO_SO },
  ],
  routerListXHNV: [
    { key: 'gioi-thieu', title: 'Giới thiệu', to: ROUTER.GIOI_THIEU },
    { key: 'dang-ky-ho-so', title: 'Đăng ký hồ sơ', to: ROUTER.DANG_KY_HO_SO },
  ]
}