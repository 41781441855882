import { makeStyles } from "@material-ui/core";
import backdrop from "../../../assets/image/home/backdrop.jpg";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%"
  },
  imageHead: {
    backgroundImage: `url(${backdrop})`,
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: "50px",
    paddingBlock: "50px",
    position: "absolute",
    left: 0,
    right: 0,
    top: 64,
    height: 140,
  },
  logoHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
    "& .group-logo": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& p": {
        fontSize: "14px",
        color: "#FFFFFF",
        textAlign: "center",
        fontFamily: "WorkSans-ExtraBold"
      }
    }
  },

  boxTime: {
    width: "100%",
    marginTop: "50px",
    padding: "0px 0px 30px 0px",
    borderRadius: "8px",
    background: `linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,107,249,1) 0%, rgba(107,187,255,1) 100%)`,
    "& .MuiGrid-container": {
      height: "100%",
    },
    "& .MuiGrid-item": {
      marginTop: "30px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#FFFFFF",
      "& p": {
        "&:first-child": {
          fontSize: "1.5rem",
          fontWeight: 600
        },
        "&:last-child": {
          fontSize: "3.75rem",
          fontWeight: 600
        }
      }
    }
  },

  filter: {
    marginTop: 140 + 64 + 64,
  },

  cards: {
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    "& .card-area": {
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      padding: "0px 0px 20px 0px",
      marginTop: "40px",
      border: "1px solid rgba(0,0,0,0.125)",
      "&:hover": {
      }
    },
  },

  cardContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "200px",
  },

  gridCard: {
    // padding: "0px 20px 0px 20px",
  },

  info: {
    // width: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },

  
  header: {
    borderRadius: "0px",
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    height: "180px",
  },
  
  vote: {
    // width: "80%",
    padding: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    "& .MuiButton-root": {
      border: "1px solid gray",
    },
    "& .MuiButton-text": {
      padding: "6px 20px"
    }
  },

  boxAvatar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
  },
  
  name: {
    fontWeight: 600,
    fontSize: "1.2rem",
    marginBottom: "20px",
    marginTop: "10px",
    textAlign: "left",
  },

  avatar: {
    width: 100,
    height: 100,
    marginTop: -50,
    marginLeft: 20,
    marginRight: 20,
  }
})