export const APP_SCENE = process.env.REACT_APP_SCENE.toUpperCase();
export const PRIMARY_COLOR = '#031043';
export const SECONDARY_COLOR = '#ee6064';
export const MOBILE_WIDTHS_SIZE = 768;


export const CATEGORY = { QCV: 'QCV', PTNS: 'PTNS', XHNV: 'XHNV' };
export const CATEGORY_TITLE = {
  [CATEGORY.QCV]: 'Giải thưởng Khoa học công nghệ Quả Cầu Vàng',
  [CATEGORY.PTNS]: 'Giải thưởng Nữ sinh Khoa học công nghệ Việt Nam',
  [CATEGORY.XHNV]: 'Giải thưởng Khuê Văn Các',
}
export const ROLE = { SUPER_ADMIN: 'super_admin' }


export const KEYS = {
  DOMESTIC: 'domestic',
  INTERNATIONAL: 'international',
  DAO_TAO: 'daoTao',
  BOI_DUONG: 'boiDuong',

  SOCIAL_ACTIVITY: 'socialActivity',
  PERSONAL_PROCESS: 'personalProcess',
  OTHER_ACHIEVEMENT: 'otherAchievement',
  PERSONAL_ACHIEVEMENT: 'personalAchievement',

  HTQT: 'htqt',
  HTQG: 'htqg',
  HTCS: 'htcs',
  NCKHCB: 'nckhcb',
  NCKHCT: 'nckhct',
}


export const ARCHIVEMENT_TYPE = {
  REWARD: 'reward',
  SOLUTION: 'solution',
  MONOGRAPH: 'monograph',
  TECH_COMPANY: 'techCompany',
  HR_DEVELOPMENT: 'hrDevelopment',
  RESEARCH_PROJECT: 'researchProject',
  SCIENTIFIC_REPORT: 'scientificReport',
  SCIENTIFIC_RESEARCH: 'scientificResearch',
}


export const ARCHIVEMENT_FORM = {
  [ARCHIVEMENT_TYPE.REWARD]: 'formReward',
  [ARCHIVEMENT_TYPE.SOLUTION]: 'formSolution',
	[ARCHIVEMENT_TYPE.MONOGRAPH]: 'formMonograph',
	[ARCHIVEMENT_TYPE.TECH_COMPANY]: 'formTechCompany',
	[ARCHIVEMENT_TYPE.HR_DEVELOPMENT]: 'formHrDevelopment',
	[ARCHIVEMENT_TYPE.RESEARCH_PROJECT]: 'formResearchProject',
	[ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT]: 'formScientificReport',
	[ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH]: 'formScientificResearch',
}


// TODO: Chuyển về enums của QCV
export const ARCHIVEMENT_TITLE = {
  [ARCHIVEMENT_TYPE.REWARD]: 'Giải thưởng, huy chương đã đạt được',
  [ARCHIVEMENT_TYPE.SOLUTION]: 'Bằng độc quyền sáng chế, giải pháp hữu ích thuộc lĩnh vực xét thưởng',
  [ARCHIVEMENT_TYPE.MONOGRAPH]: 'Sách chuyên khảo, chương sách',
  [ARCHIVEMENT_TYPE.TECH_COMPANY]: 'Sản phẩm khoa học công nghệ',
  [ARCHIVEMENT_TYPE.RESEARCH_PROJECT]: 'Chương trình, dự án, đề tài nghiên cứu khoa học',
  [ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT]: 'Bài báo khoa học đăng trên hội thảo',
  [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH]: 'Bài báo khoa học đăng trên tạp chí',
  [ARCHIVEMENT_TYPE.HR_DEVELOPMENT + KEYS.DAO_TAO]: 'Đào tạo nhân lực',
  [ARCHIVEMENT_TYPE.HR_DEVELOPMENT + KEYS.BOI_DUONG]: 'Bồi dưỡng nhân lực',
  [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL]: 'Bài báo khoa học đăng trên tạp chí quốc tế',
  [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC]: 'Bài báo khoa học đăng trên tạp chí trong nước',
}


