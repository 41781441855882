import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';
import {
  Box,
  Dialog,
  Divider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  Tooltip,
  Button,
} from '@material-ui/core';

import KPTextform from '../../../components/KPTComponents/KPTextform';

import {
  fileActions,
  userActions,
  reportsActions,
  researchActions,
  monographActions,
  researchProjectActions,
  solutionActions,
  rewardActions,
} from '../../../actions';
import { dateFormatter } from '../../../utils';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { ARCHIVEMENT_TYPE, CATEGORY, KEYS } from '../../../constants/enums';
import { FORM_CONSTANTS, MONOGRAPHS_CONSTANTS, REPORTS_CONSTANTS, REWARD_CONSTANTS, SOLUTIONS_CONSTANTS } from '../../../constants';

import { reportForm } from '../../QCV/BaoCao/BaoCaoForm/BaoCaoForm';
import { monographForm } from '../../QCV/SachChuyenKhao/BangSachForm/BangSachForm';
import { researchProjectForm } from '../../QCV/DuAnNghienCuu/DuAnForm/DuAnForm';
import { domesticResearchForm } from '../../QCV/BaiBao/BaiBaoTrongNuocForm/BaiBaoTrongNuocForm';
import { internationalResearchForm } from '../../QCV/BaiBao/BaiBaoQuocTeForm/BaiBaoQuocTeForm';
import { solutionForm } from '../../QCV/BangSangChe/SangCheForm/SangCheForm';
import { rewardForm } from '../../QCV/GiaiThuong/GiaiThuongForm/GiaiThuongForm';


const NCKH_FROM = (key, disabled) => ([
  { key: 'title', width: 12, height: 1, label: 'Tên đề tài', disabled, required: true },
  { key: 'role', width: 6, label: 'Vai trò', options: FORM_CONSTANTS.ROLE_OPTIONS.slice(0, 2), disabled, required: true },
  { key: 'type', width: 6, label: 'Loại giải thưởng', options: REWARD_CONSTANTS.TYPE_OPTIONS[key], disabled, required: true },
  { key: 'decisionDay', width: 12, label: 'Ngày quyết định khen thưởng', type: 'date', disabled, required: true },
  { key: 'previewFile', width: 12, type: 'file', disabled, required: true },
]);

export default function ThanhTichInsert(props) {
  const dispatch = useDispatch();
  const isMobileMode = useScreenSize();

  const { dialog, setDialog } = props;

  const profile = useSelector((state) => state.profileReducer.profile);

  const [edit, setEdit] = useState(true);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const [loadingDoi, setLoadingDoi] = useState(false);

  useEffect(() => {
    if (dialog?.data) {
      // setEdit(dialog?.data?.type === 'q6' && !dialog?.data?.title);
      setEdit(!dialog?.data?.title);
      setValues(dialog.data);
    } else {
      setEdit(true);
    }
  }, [dialog?.data]);

  useEffect(() => {
    if (dialog) setOpen(true);
  }, [dialog]);

  const onSuccessEvent = (type) => {
    switch (type) {
      case ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC:
      case ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL:
        return (doi) => {
          const { data: { info, journal } } = doi;
          setValues({
            ...values,
            conferenceTitle: journal?.type === 'conference and proceedings' ? journal?.title : null,
            type: type !== ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC ? (
              journal?.coreCollection === 'Scopus' ? 'scopus' : journal?.sjrBestQuartile.toLowerCase()
            ) : 'q6',
            link: info?.URL,
            title: info?.title,
            publisher: info?.publisher,
            publishYear: parseInt(info?.issued['date-parts']?.[0]?.[0]),
            page: info?.page,
            issn: info?.ISSN?.[0],
            numberAuthor: info?.author?.length,
            author: info?.author?.map((e) => `${e.given} ${e.family}`).join(', '),
          });
          setLoadingDoi(false);
          dispatch(userActions.notification({ message: 'Lấy dữ liệu thành công', type: 'success' }));
        }
      case ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT:
        return (doi) => {
          const { data: { info, journal } } = doi;
          setValues({
            ...values,
              conferenceTitle: journal?.type === 'conference and proceedings' ? journal?.title : null,
              type: journal?.coreCollection === 'Scopus' ? 'scopus' : journal?.sjrBestQuartile.toLowerCase(),
              link: info?.URL,
              title: info?.title,
              publisher: info?.publisher,
              publishYear: info?.issued['date-parts']?.[0]?.[0],
              page: info?.page,
              issn: info?.ISSN?.[0],
              numberAuthor: info?.author?.length,
              author: info?.author?.map((e) => (`${e.given} ${e.family}`)).join(', ')
          });
          setLoadingDoi(false);
          dispatch(userActions.notification({ message: 'Lấy dữ liệu thành công', type: 'success' }));
        }
      default:
        return null;
    }
  }

  const doiEvents = {
    loading: loadingDoi,
    init: () => {
      setLoadingDoi(true);
      console.log('ok')
    },
    onSuccess: null,
    onDoiFailure: (doi) => {
      setLoadingDoi(false);
      dispatch(userActions.notification({ message: doi.message, type: 'error' }));
    }
  };

  const getForm = () => {
    const vals = values || {};

    const formMap = {
      [ARCHIVEMENT_TYPE.SOLUTION]: solutionForm(vals, isMobileMode, !edit, SOLUTIONS_CONSTANTS.TYPE_OPTIONS),
      [ARCHIVEMENT_TYPE.SOLUTION + CATEGORY.PTNS]: solutionForm(vals, isMobileMode, !edit, SOLUTIONS_CONSTANTS.TYPE_PTNS_OPTIONS),
      [ARCHIVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL]: monographForm(
        vals,
        isMobileMode,
        !edit,
        MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL
      ),
      [ARCHIVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC]: monographForm(
        vals,
        isMobileMode,
        !edit,
        MONOGRAPHS_CONSTANTS.TYPE_OPTIONS.DOMESTIC
      ),
      [ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT]: reportForm(
        vals,
        isMobileMode,
        !edit,
        { ...doiEvents, onSuccess: onSuccessEvent(ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT) },
        REPORTS_CONSTANTS.TYPE_OPTIONS.ALL,
      ),
      [ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL]: reportForm(
        vals,
        isMobileMode,
        !edit,
        { ...doiEvents, onSuccess: onSuccessEvent(ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT) },
        REPORTS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL
      ),
      [ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC]: reportForm(
        vals,
        isMobileMode,
        !edit,
        { ...doiEvents, onSuccess: onSuccessEvent(ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT) },
        REPORTS_CONSTANTS.TYPE_OPTIONS.DOMESTIC
      ),
      [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL]: internationalResearchForm(
        vals,
        isMobileMode,
        !edit,
        { ...doiEvents, onSuccess: onSuccessEvent(ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL) },
        REPORTS_CONSTANTS.TYPE_OPTIONS.INTERNATIONAL
      ),
      [ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC]: domesticResearchForm(
        vals,
        isMobileMode,
        !edit,
        { ...doiEvents, onSuccess: onSuccessEvent(ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC) },
        REPORTS_CONSTANTS.TYPE_OPTIONS.DOMESTIC
      ),
      [ARCHIVEMENT_TYPE.RESEARCH_PROJECT]: researchProjectForm(vals, !edit),
      [ARCHIVEMENT_TYPE.REWARD + KEYS.HTQT]: rewardForm(
        vals,
        isMobileMode,
        !edit,
        REWARD_CONSTANTS.TYPE_OPTIONS[KEYS.HTQT]
      ),
      [ARCHIVEMENT_TYPE.REWARD + KEYS.HTQG]: rewardForm(
        vals,
        isMobileMode,
        !edit,
        REWARD_CONSTANTS.TYPE_OPTIONS[KEYS.HTQG]
      ),
      [ARCHIVEMENT_TYPE.REWARD + KEYS.HTCS]: rewardForm(
        vals,
        isMobileMode,
        !edit,
        REWARD_CONSTANTS.TYPE_OPTIONS[KEYS.HTCS]
      ),
      [ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCB]: NCKH_FROM(KEYS.NCKHCB, !edit),
      [ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCT]: NCKH_FROM(KEYS.NCKHCT, !edit),
    };

    if (dialog?.type && dialog?.type in formMap) {
      const tempTextForm = formMap[dialog.type];

      // Trường hợp đặc biệt
      if (dialog.type === ARCHIVEMENT_TYPE.RESEARCH_PROJECT && values?.level !== 'domestic') tempTextForm.splice(5, 1);

      return tempTextForm;
    } else {
      return [];
    }
  }

  const textForm = useMemo(() => getForm(), [dialog, values, edit, loadingDoi]);

  // const reportTextForm = reportForm(values || {}, isMobileMode, !edit, doiEvents[ARCHIVEMENT_TYPE.])
  // const rewardTextForm = rewardForm(values || {}, isMobileMode, !edit);
  // const monographTextForm = monographForm(values || {}, isMobileMode, !edit);
  // const internationalResearchTextForm = internationalResearchForm(values || {}, isMobileMode, !edit, doiEvents);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setEdit(true);
      setDialog(null);
      setValues(null);
    }, 255);
  };

  //submit sieu dai
  const handleSubmit = async () => {
    var data = { ...values, profileId: profile?.id };
    if (values?.previewFile?.[0]) {
      const link = await dispatch(fileActions.importPreviewFile(profile?.id, values?.previewFile[0]));
      data = { ...data, link };
    }
    switch (dialog.type) {
      case ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.INTERNATIONAL:
        data = { ...data, filterType: KEYS.INTERNATIONAL };
        if (!data?.id) dispatch(researchActions.insertResearch([data]));
        else dispatch(researchActions.updateResearch(data));
        break;
      case ARCHIVEMENT_TYPE.SCIENTIFIC_RESEARCH + KEYS.DOMESTIC:
        data = { ...data, filterType: KEYS.DOMESTIC };
        if (!data?.id) dispatch(researchActions.insertResearch([data]));
        else dispatch(researchActions.updateResearch(data));
        break;
      case ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT:
      case ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.DOMESTIC:
      case ARCHIVEMENT_TYPE.SCIENTIFIC_REPORT + KEYS.INTERNATIONAL:
        if (!data?.id) dispatch(reportsActions.insertReports([data]));
        else dispatch(reportsActions.updateReport(data));
        break;
      case ARCHIVEMENT_TYPE.MONOGRAPH + KEYS.INTERNATIONAL:
      case ARCHIVEMENT_TYPE.MONOGRAPH + KEYS.DOMESTIC:
        if (!data?.id) dispatch(monographActions.insertMonograph([data]));
        else dispatch(monographActions.updateMonograph(data));
        break;
      case ARCHIVEMENT_TYPE.RESEARCH_PROJECT:
        data = {
          ...data,
          start_at: dateFormatter(data.start_at),
          finish_at: dateFormatter(data.finish_at),
        }
        if (!data?.id) dispatch(researchProjectActions.insertResearchProject([data]));
        else dispatch(researchProjectActions.updateResearchProject(data));
        break;
      case ARCHIVEMENT_TYPE.SOLUTION:
        data = {
          ...data,
          issueAt: dateFormatter(data.issueAt)
        }
        if (!data?.id) dispatch(solutionActions.insertSolution([data]));
        else dispatch(solutionActions.updateSolution(data));
        break;
      case ARCHIVEMENT_TYPE.REWARD + KEYS.HTQT:
      case ARCHIVEMENT_TYPE.REWARD + KEYS.HTQG:
      case ARCHIVEMENT_TYPE.REWARD + KEYS.HTCS:
      case ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCB:
      case ARCHIVEMENT_TYPE.REWARD + KEYS.NCKHCT:
        data = {
          ...data,
          decisionDay: dateFormatter(data.decisionDay),
        }
        if (!data?.id) dispatch(rewardActions.insertReward([data]));
        else dispatch(rewardActions.updateReward(data));
        break;
      default:
        break;
    }

    setValues(null);
    handleClose();
  };

  const disabledSave = !(
    (dialog?.type === 'researchQT' || dialog?.type === 'researchTN' || dialog?.type === 'report')
    && (!values?.link || values?.link === '')
    && (!values?.previewFile || values?.previewFile?.length === 0)
  );


  return (
    <Dialog fullWidth maxWidth='sm' open={open}>
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'>
            <b>{dialog?.actionType === "edit" ? "Chi tiết thành tích" : "Thêm thành tích"}</b>
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <KPTextform
          id='dialog-form'
          values={values}
          textForm={textForm}
          setValues={setValues}
          handleSubmit={handleSubmit}
        />
      </DialogContent>
      <DialogActions style={{ padding: '8px 24px' }}>
        <Button variant='outlined' onClick={handleClose}>
          Huỷ
        </Button>
        {edit ? (
          <Tooltip
            title='Chưa nhập link DOI hoặc file Minh chứng'
            disableHoverListener={disabledSave}
          >
            <span>
              <Button disabled={!disabledSave} variant='contained' color='secondary' type='submit' form='dialog-form'>
                Lưu
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button variant='contained' color='secondary' onClick={() => setEdit(true)}>
            Chỉnh sửa
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
