import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@material-ui/core";

import qcv from "../../assets/image/tnv/qcv-icon.png";
import xhnv from "../../assets/image/tnv/xhnv-icon.png";
import nstb from "../../assets/image/tnv/nstb-icon.png";
import { CATEGORY, CATEGORY_TITLE } from "../../constants/enums";

const url = window.location.href;
const isLocalhost = url.includes("localhost");

const topicList = [
  {
    key: CATEGORY.QCV,
    title: CATEGORY_TITLE[CATEGORY.QCV],
    imgSource: qcv,
    containerColor: "#F5F1EE",
    to: isLocalhost
      ? url.replace('/tong-quan', '')
      : process.env.REACT_APP_BRANCH === "development"
      ? "http://qcv.orlab.com.vn"
      : "https://qcv.tainangviet.vn",
    buttonText: "Truy cập",
    description: "Giải thưởng khoa học công nghệ Quả Cầu Vàng là phần thưởng cao quý của Trung ương Đoàn TNCS Hồ Chí Minh và Bộ Khoa học & Công nghệ dành cho các tài năng trẻ xuất sắc trong lĩnh vực khoa học công nghệ hàng năm.",
  },
  {
    key: CATEGORY.PTNS,
    title: CATEGORY_TITLE[CATEGORY.PTNS],
    imgSource: nstb,
    containerColor: "#EFEEF5",
    to: isLocalhost
      ? url.replace('/tong-quan', '')
      : process.env.REACT_APP_BRANCH === "development"
      ? "http://gtns.orlab.com.vn"
      : "http://gtns.tainangviet.vn",
    buttonText: "Truy cập",
    description: "Giải thưởng Nữ sinh khoa học công nghệ Việt Nam là phần thưởng của Trung ương Đoàn TNCS Hồ Chí Minh và Bộ Khoa học & Công nghệ dành cho các nữ sinh viên có thành tích học tập, nghiên cứu khoa học xuất sắc trong lĩnh vực khoa học công nghệ hàng năm.",
  },
  {
    key: CATEGORY.XHNV,
    title: CATEGORY_TITLE[CATEGORY.XHNV],
    imgSource: xhnv,
    containerColor: "#F4F5EE",
    to: isLocalhost
      ? url.replace('/tong-quan', '')
      : process.env.REACT_APP_BRANCH === "development"
      ? "http://khxhnv.orlab.com.vn"
      : "http://khxhnv.tainangviet.vn",
    buttonText: "Truy cập",
    description: "Giải thưởng Khuê Văn Các là phần thưởng cao quý của Trung ương Đoàn TNCS Hồ Chí Minh dành cho các nhà khoa học trẻ Việt Nam tiêu biểu trong lĩnh vực khoa học xã hội và nhân văn."
  },
];

export default function GioiThieu() {
  return (
    <Grid
      container
      spacing={2}
      alignContent="center"
      justifyContent="center"
    >
      {topicList.map((e) => (
        <Grid key={e.key} item xs={12} sm={6} md={4}>
          <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <CardMedia
              src={e.imgSource}
              style={{ backgroundColor: e.containerColor }}
            >
              <Box paddingY={2}>
                <CardMedia
                  src={e.imgSource}
                  component="img"
                  style={{ width: '40%', margin: "auto" }}
                />
              </Box>
            </CardMedia>
            <CardContent style={{ flexGrow: 1 }}>
              <Typography variant="h5" style={{ textAlign: "center" }}>
                <b>{e.title}</b>
              </Typography>
              <Typography style={{ textAlign: "justify", marginTop: 16 }}>
                {e.description}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                href={e.to}
                variant="contained"
                color="secondary"
                style={{ minWidth: "50%", margin: "auto" }}
              >
                {e.buttonText}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
